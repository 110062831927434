/* Tablet Devices */


@media (max-width:768px) {

	.custombutton-left {
		display: inline-flex;
	}

	.pay-content-right {
		height: auto;
	}
	.ccdownload-modal {
		min-width: 80%;
		max-width: 80%;
	}
	.pay-container {
		display: block;
	}

	.pay-display-box.pay-location {
		min-height: 260px;
	}

	.step-layout {
		margin: 0px !important;
	}

	.custom-button {
		width: 160px !important;
	}

	.custom-button-text-container {
		font-size: 10px !important;
	}

	.pay-content-right .pay-display-box .pay-button {
		margin: 15px 0px 0px 15px !important;
	}

	.complete-wizard-welcome-intro > .margin-left-5 {
		margin-left: 0px;
	}

	.content-top, .content-left, .content-wrapper-full {
		margin: 0px;
		padding: 0px;
		height: 100%;
	}

	.margin-top-20 {
		margin-top: 0px !important;
	}

	.content-right, .content-wrapper-full {
		height: 100%;
	}

	.step-layout-header {
		font-size: 14px;
	}

	#awesome-pdf-viewer > .main > .right-panel {
		width: 400px;
	}

	.myaccount .modal-content {
		width: 100%;
		margin-left: 0%;
	}

	.welcome-container {
		padding-top: 10px !important;
	}

	.shepherd-element.shepherd-element-left.shepherd-enabled, .shepherd-element.shepherd-element-right.shepherd-enabled {
		top: -0px !important;
	}

	/*Attachment Table Style Starts here*/

	.attachment-tab-container {
		max-height: inherit;
	}

		.attachment-tab-container table, .attachment-tab-container thead, .attachment-tab-container tbody, .attachment-tab-container th, .attachment-tab-container td, .attachment-tab-container tr {
			display: block;
		}

			.attachment-tab-container thead tr :not(:first-child) {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}


		.attachment-tab-container td {
			border: none;
			position: relative;
			padding-left: 40%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			min-height: 44px;
		}

			.attachment-tab-container td:before {
				position: absolute;
				top: 6px;
				left: 6px;
				width: 45%;
				padding-right: 10px;
				white-space: nowrap;
				padding-top: 5px;
			}

			.attachment-tab-container td:nth-of-type(1):before {
				content: "";
			}

			.attachment-tab-container td:nth-of-type(2):before {
				content: "File Name";
			}

			.attachment-tab-container td:nth-of-type(3):before {
				content: "Instructions";
			}

	.attachment-download-icon {
		position: absolute;
		right: 10px;
		/*top: -5px;*/
	}

	.attachment-file-viewer {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 160px;
	}
	/*Attachment Table Style Ends here*/

	.attachment-tab-container .react-bs-container-body {
		max-height: inherit !important;
		overflow-y: inherit !important;
		overflow-x: inherit !important;
	}
}
 
