@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables.scss";

:root {
  --control-background-color: #f4f9f0;
  --control-border-color: #88c656;
  --border-color-gray: #d5d5d5;
}

/*-------- Scroll Bar CSS---Start-- */

::-webkit-scrollbar {
  width: 8px;
  background-color: inherit;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f6f6f6;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $brand-primary-blue-2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $brand-primary-blue-2;
}

/*-------- Scroll Bar CSS---End---- */

html {
  height: 100%;
}

body {
  background-color: #3d3e3f !important;
  font-family: $font-family;
  font-size: 16px;
  line-height: 1.5;
  color: $primary-font-color;
  height: 100%;
  overflow: hidden;
}

h1 {
  font-family: $font-family;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h3 {
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}

p {
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}

blockquote {
  font-family: $font-family;
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}

pre {
  font-family: $font-family;
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

#overlay-loader[data-show="true"] {
  display: block !important;
}

#overlay-loader {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}

#overlay-loader > .spinner {
  margin: 0 auto;
  height: 40px;
  width: 40px;
  animation: rotate 0.8s infinite linear;
  border: 3px solid #0973ba;
  /*border: 3px solid var(--headerBgColor);*/
  border-right-color: transparent;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  position: fixed;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#app {
  background-color: #ffffff;
  height: 100vh;
  height: 100svh;
}

.app-header {
  height: 55px;
  background-color: $brand-secondary-blue;
  background-color: var(--headerBgColor);
  padding: 15px 20px;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
}

.popover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

#contactInfoPopover {
  width: auto;
  height: auto;
}

#contactInfoPopover .popover-arrow::after,
.popover-arrow::before {
  border-bottom-color: #000;
}

.popover-header {
  background-color: #f0f0f0;
  color: $primary-font-color;
  font-weight: 500;
  line-height: 1.2;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.popover-body {
  font-size: 16px;
  line-height: 1.5;
  padding: 16px;
  font-family: "Mulish", sans-serif;
}

.popover-body .medium {
  color: $primary-font-color;
  font-weight: 700;
}

.popover-body .mail {
  color: $brand-primary-blue-2;
  font-weight: 400;
}

.popover-body .mail a:hover {
  cursor: pointer;
}

.headerForeColor {
  color: white;
  color: var(--headerForeColor) !important;
}

.ddl-icon {
  margin-right: 10px;
  color: #0973ba;
  /*color: var(--headerBgColor);*/
  width: 15.75px;
}

.btn-icon {
  margin-right: 10px;
}

#accountPopover {
  width: 240px;
  border-radius: 4px;
  inset: 6px auto auto 0px !important;

  .arrow {
    display: none;
  }

  .profile-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .popover-body {
    padding: 8px 0;

    .profile-name {
      padding: 8px 16px;
      color: $brand-primary-blue-2;
      font-weight: 700;
      line-height: 1.2;
    }

    .my-account {
      padding: 4px 16px;
      color: #151617;
      font-weight: 400;
      cursor: pointer;
    }

    .dropdown-item {
      &:hover {
        background-color: $gray;
      }

      &:active {
        background-color: $brand-primary-blue-1;
        color: $white;
      }
    }

    .dropdown-divider {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      margin: 8px 0;
      opacity: 1;
      overflow: hidden;
      box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.15);
    }

    .log-out {
      padding: 4px 16px;
      color: #151617;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.account-menu a {
  color: #0973ba !important;

  /*color: var(--headerBgColor) !important;*/
}

.account-menu a:hover {
  color: #0973ba !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

/*-------------Font Face -------------*/

@font-face {
  font-family: "Journal";
  src: url("../fonts/journal.eot");
  src: url("../fonts/journal.eot?#iefix") format("embedded-opentype"),
    url("../fonts/journal.woff") format("woff"),
    url("../fonts/journal.ttf") format("truetype"),
    url("../fonts/journal.svg#JournalRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.no-padding-left {
  padding-left: 0px !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.no-padding-top {
  padding-top: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.no-padding {
  padding: 0px 0px 0px 0px !important;
}

.no-margin {
  margin: 0 !important;
}

.width-100 {
  width: 100%;
}

.width-98 {
  width: 98%;
}

.font-style-italic {
  font-style: italic;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-18 {
  margin-right: 18px !important;
}

.margin-right-8 {
  margin-right: 8px !important;
}

.padding-left-6 {
  padding-left: 6px;
}

.height-100 {
  height: 100%;
}

.popover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

._loading_overlay_wrapper {
  min-height: 100vh;
}

._loading_overlay_overlay {
  margin-top: -52px;
}

.scroller {
  scrollbar-width: thin;
}

/* Initial application loading icon - START */
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #ffffff;
  text-indent: -9999em;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* Initial application loading icon - END */

/* Layout - Start */

header .company-name {
  color: white;
  color: var(--headerForeColor);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

header .company-name.long-text {
  width: 400px;
  font-size: 14px;
  white-space: wrap;
}

.logo-img {
  height: 25px;
}

.content-wrapper {
  background-color: #fff;
  height: calc(100vh - 85px);
  height: calc(100svh - 85px);

  .steps-wrapper {
    .steps-footer {
      border-top: $multi-step-border;
    }
  }
}

.header-left-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: auto;
}

.header-right-container {
  margin-left: auto;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  min-width: 175px;
}

.header-mydownload,
.header-contact-info,
.header-home-info,
.header-logout,
.header-account,
.header-taxYear,
.header-taxReturn,
.header-back-to-controller {
  float: right;
  color: white;
  color: var(--headerForeColor);
  cursor: pointer;
  margin-left: 15px;
}

.header-draftStatus {
    margin-left: 17px;
    width: 80px;
    padding: 5px 13px 5px 14px;
    border: 1px solid var(--Denim-400, #717F93);
    background-color: #E7EAED;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .draftText {
        margin-left: 6px;
        color: #13294B;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
    }
}

.header-contact-info a {
  color: white;
  color: var(--headerForeColor);
  display: flex;
  align-items: center;
}

.header-contact-info a span {
  padding-top: 3px;
  margin-left: 10px;
  font-weight: 500;
  line-height: 1.6;
}

.header-home-info a {
  color: white;
  color: var(--headerForeColor);
  display: flex;
  align-items: center;
}

.header-home-info a span {
  padding-top: 3px;
  margin-left: 10px;
  font-weight: 500;
  line-height: 1.6;
}

.header-contact-info:hover,
.header-home-info:hover,
.header-logout:hover {
  text-decoration: underline;
}

.header-taxYear a {
  display: flex;
  align-items: center;
}

.header-taxYear a span {
  padding-top: 3px;
  margin-left: 10px;
  font-weight: 500;
  line-height: 1.6;
}

.header-account a {
  display: flex;
  align-items: center;
}

.header-account a span {
  padding-top: 3px;
  margin-right: 10px;
}

.header-taxYear:hover {
  cursor: text;
}

.account-menu,
.taxYear-menu,
.taxReturn-menu {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

/* .header-account a::after
, .header-taxYear a::after, .header-taxReturn a::after {
    content: "";
    width: 0px;
    height: 1px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #ffffff;
    position: absolute;
    margin-left: 5px;
    margin-top: 8px;
    z-index: 100;
} */

.sidebarIconToggle,
#sidebarMenu {
  display: none;
}

#sidebarMenu {
  height: 100%;
  position: fixed;
  top: 55px;
  left: 0;
  width: 225px;
  transform: translateX(-250px);
  transition: transform 250ms ease-in-out;
  /*background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);*/
  background-color: #0973ba;
  /*background-color: var(--headerBgColor);*/
  overflow: scroll;
  z-index: 999;
}

.sidebarMenuInner {
  margin: 0;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li {
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarMenuInner li span {
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}

.sidebarMenuInner li a {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

#openSidebarMenu {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}

.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  top: 19px;
  left: 15px;
  height: 22px;
  width: 22px;
  margin-bottom: 0;
}

.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}

.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}

.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}

.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}

.show {
  display: block !important;
}

.sidebar {
  width: 225px !important;
  min-height: calc(100vh - 42px);
  user-select: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 1.5rem !important;
}

.navbar-nav {
  float: left;
  margin: 0;
}

.navbar-nav > li {
  float: left;
  border-top: 1px solid #fff;
}

.navbar-nav > li:last-child {
  border-bottom: 1px solid #fff;
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
  position: relative;
}

.sidebar .nav-item .nav-link {
  display: block;
  text-align: left;
  padding: 1rem;
  width: 225px;
}

.sidebar .nav-item .nav-link {
  color: #fff;
}

.sidebar .nav-item.layout-dropdown .layout-dropdown-toggle::after {
  display: block;
}

navbar-nav .nav-item.layout-dropdown .layout-dropdown-toggle span {
  margin: 10px;
}

.sidebar .nav-item .nav-link span {
  font-size: 1.5rem;
  display: inline;
}

.nav-link span {
  color: #fff;
  text-shadow: none;
}

.layout-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.layout-dropdown-menu {
  box-shadow: none;
  position: static !important;
  margin-left: 30px;
  top: 0;
  background-color: transparent;
  border: none;
}

.navbar-nav .layout-dropdown-menu {
  position: static;
  float: none;
}

.sidebar .nav-item .layout-dropdown-menu {
  -webkit-transform: none !important;
  transform: none !important;
  left: calc(50px + 0.5rem) !important;
  margin: 0;
}

.layout-dropdown-menu .layout-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 14px;
}

.layout-dropdown-menu.show {
  padding-top: 0px;
}

a.active.layout-dropdown-item {
  text-decoration: underline;
}

/* Layout - END */

/* Summary Page Start */

.content-right,
.content-wrapper-full {
  padding-right: 125px;
  margin-top: 0px !important;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  height: 98%;
}

.content-left > div {
  // padding: 10px 0px 0px 10px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}

.estimated-voucher-container {
  height: 48%;
  margin-top: 3%;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.payment-refund-container .title,
.estimated-voucher-container .title,
.tax-payment-container .title,
.payment-instruction-container .title {
  margin-bottom: 5px;
  margin-left: 0px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #000000;
}

.payment-refund-container .body,
.estimated-voucher-container .body {
  padding: 0px 10px;
  overflow: auto;
  height: 85%;
}

.completebtn {
  padding: 8px 16px;
  width: 139px;
  height: 48px;
  position: absolute;
  right: 98px;
  margin-top: 20px;
}

.sub-section .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding: inherit;
}

.bold {
  color: #000;
}

.fllft {
  float: left;
}

.green {
  color: #669440;
}

.red {
  color: #cc4a43;
}

.flrt {
  float: right;
}

.semibold {
  font-weight: 600;
}

.currencytext {
  text-align: right;
  padding-right: 0px;
  /*font-family: monospace;*/
  font-family: sans-serif, "Roboto", "Poppins";
}

.content-left .section-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: $input-border-color;
  clear: both;
}

.content-left .preSelect:first-of-type {
  clear: both;
  margin: 20px 1px;
}

.preSelect {
  clear: both;
  margin: 40px 1px;
}

.sub-section {
  clear: both;
}

.total-label-green {
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  color: #669440;
}

.total-label-red {
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  color: #cc4a43;
}

.total-amount-green {
  border-top: 0.5px solid #6b7075;
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: normal;
  width: 40% !important;
}

.total-amount-red {
  border-top: 0.5px solid #6b7075;
  font-size: 14px;
  font-weight: 800;
  width: 40% !important;
}

.content-left .sub-section div {
  width: 50%;
  position: relative;
}

.content-left .sub-section .authority {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $primary-font-color;
}

.content-left .sub-section .amount {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.content-right .welcome {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: $primary-font-color;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-left-1 {
  margin-left: 1%;
}

.content-right .welcome-intro {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: $primary-font-color;
  margin: 20px 0px 40px 0px;
}

.completed-img-btn {
  display: flex;
  flex-wrap: wrap;
}

.step-img-btn > div > svg,
.completed-img-btn > div > svg {
  height: 92px;
  cursor: pointer;
}

.step-img-btn .img-caption,
.completed-img-btn .img-caption {
  width: 163px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.4;
  text-align: center;
  color: $primary-font-color;
  margin-top: 15px;
}

.welcome-page-step-img-btn {
  text-align: center;
  width: 100%;
}

.welcome-page-step-img-btn > div > svg {
  height: 92px;
  cursor: pointer;
}

.welcome-page-step-img-btn .img-caption {
  width: 163px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.4;
  text-align: center;
  color: $primary-font-color;
  margin-top: 15px;
}

.welcome-page-step-img-btn {
  text-align: center;
  width: 100%;
}

.summary-begin-btn {
  width: 260px;
  height: 58px;
}

.summary-begin-small-btn {
  width: 260px;
  height: 48px;
  margin: 0px !important;
}

.summary-start-step-container {
  text-align: center;
  width: 100%;
}

.summary-start-step-container {
  button {
    width: 200px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: $white;
    border-radius: 50px !important;
    margin: 6% 0% !important;
  }

    &.delegatee {
        button {
            margin: 5% 0% !important;
        }
    }
}

.opacity40 {
    opacity: 0.4;
}

.last-step-buttons {
    width: 200px;
}

.icon---summary-review-documents {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.icon---summary-sign-documents {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.icon---distribute-k1s {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.icon---summary-paperfile-documents {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.icon---summary-make-payments {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.center-loading-text {
  position: absolute;
  font-size: 1.5em;
  top: 40vh;
  left: 40vw;
  z-index: 99;
  background-color: #fff;
}

/*Summary Page End*/
@media (max-width: 800px) {
  .content-wrapper-full .content-select-sign-buttons {
    display: block !important;
    margin-top: 10px;
    height: calc(100% - 80px) !important;
  }

  header .company-name {
    font-size: 16px;
  }

  .logo-img {
    height: 24px;
    margin: 0;
  }

  .welcome-page {
    padding-left: 10% !important;
    padding-right: 20% !important;
  }

  .welcome-page-container {
    padding: 10%;
  }

  .header-contact-info {
    margin: 0;
  }

  .header-right-container {
    padding-right: 10px;
  }

  .header-contact-info,
  .header-logout {
    margin: 0 0 0 20px;
  }

  .ssn-page,
  .otp-page {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .ssn-page-container,
  .otp-page-container {
    padding-bottom: 75px !important;
  }

  .access-code-expiry-info {
    margin-left: 0px !important;
  }

  .sidebarIconToggle,
  #sidebarMenu {
    display: inline-block !important;
  }

  .header-account,
  .header-taxYear,
  .header-taxReturn {
    display: none;
    padding: 0px !important;
    margin: 0px !important;
  }

  .content-top,
  .content-left,
  .content-right,
  .pay-content-left,
  pay-content-top .content-wrapper-full {
    overflow-x: hidden;
  }

  .payment-refund-container,
  .estimated-voucher-container {
  }

  .steps-wrapper {
    /*overflow: auto;*/
    /* Commented because of  bug 41444*/
  }

  .imageBorderEsign {
    margin: 24px !important;
  }

  .imageBorderManualSignText {
    margin: 9px !important;
  }

  .information-popup {
    left: 20% !important;
  }

  #ccRecipient-modal {
    min-width: 100%;
  }

  #paperfile-consent-modal {
    max-width: 95%;
    min-width: 95% !important;
  }

  .voucher-payment-container {
    max-height: 100%;
  }
}

/* Sign Page - Start */
.imageBorderEsignSelect {
  border: 2px solid #88c656;
  border-radius: 16px;
  opacity: 0.7;
  transition: 0.3s;
  background-color: #d8d8d8 !important;
  width: 216px;
  height: 141px;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.imageBorderEsignSelect:hover {
  opacity: 1;
}

.imageBorderManualSign {
  margin: 24px 0px 0px 15px;
}

.tab {
  display: inline-block;
  color: $brand-primary-blue-2;
}

.tab:hover {
  cursor: pointer;
  text-decoration: underline;
}

.imageTaxpayerSigning {
  margin: 8% 0% 0% 0%;
}

.signPageDesc {
  width: 100%;
}

.font-weight-normal-bold {
  font-weight: 600;
  line-height: 1.4;

  &-color {
    color: $brand-primary-blue-2;
  }
}

.font-weight-bold {
  font-weight: bold;
}

/* Sign Page - End */
@media (min-width: 800px) and (max-width: 1024px) {
  .steps-wrapper {
    overflow: auto;
  }

  .step-img-btn > div.col-lg-4 {
    width: 33%;
  }

  .step-img-btn > div.col-lg-3 {
    width: 25%;
  }

  .information-popup {
    left: 30% !important;
  }

  .image-tile-sizing {
    width: 50%;
    padding: 4%;
    margin: -5% !important;
  }
}

/* Esign page Start */
.content-wrapper-full {
  border-radius: 5px;
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
  padding: 15px;
}

.content-wrapper-full > div {
  background-color: #fff;
}

.content-wrapper-full .content-header {
  font-size: large;
  font-weight: 500;
  padding: 20px;
}

.content-wrapper-full input[type="checkbox"] {
  display: inline;
}

.content-wrapper-full input[type="text"] {
  border: 2px solid var(--border-color-gray);
  border-radius: 4px;
  height: 30px;
  width: 200px;
}

.content-wrapper-full .scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  display: inline !important;
}

.content-wrapper-full .dropdown-toggle.btn.btn-success {
  color: var(--control-border-color);
  background-color: var(--control-background-color);
  border-color: var(--control-border-color);
  border-radius: 4px !important;
  width: 100px;
  margin-bottom: 5px;
  text-align: left;
}

.content-wrapper-full .dropdown-toggle.btn.btn-success::after {
  position: absolute;
  right: 10px;
  top: 4px;
}

.content-wrapper-full .content-select-sign-buttons {
  height: calc(100% - 62px);
  display: inline-flex;
  align-items: center;
}

.content-wrapper-full .esign-div {
  /* width: 50%;*/
  text-align: center;
  display: block;
  /*padding-left: 21%;*/
}

.content-wrapper-full .esign-div .imageBorderEsignSelect .imageBorderEsign {
  color: #1a8fbf;
  /*margin: 65px;*/
}

.content-wrapper-full .content-select-sign-buttons .esign-div .signPageSpan1 {
  position: relative;
  display: inline-flex;
  font-weight: bold;
}

.content-wrapper-full .manusign-div {
  /*width: 50%;*/
  text-align: center;
  display: block;
}

.content-wrapper-full .manusign-div .imageBorderEsignSelect .imageManualSigner {
  margin: 0%;
  width: 93%;
}

.content-wrapper-full
  .manusign-div
  .imageBorderEsignSelect
  .imageBorderManualSignText {
  color: #1a8fbf;
  margin: 54px;
}

.content-wrapper-full .manusign-div .signPageSpan2 {
  position: relative;
  font-weight: bold;
  /*margin: 1% 0% 0% 1%;*/
}

.padding-full-20 {
  padding: 20px;
}

.margin-right-15p {
  margin-right: 15%;
}

.YearMonthDay {
  display: inline;
  padding-left: 0px;
}

.YearMonthDay .select-control {
  display: inline;
}

.YearMonthDay .select-control .show.dropdown {
  display: inline !important;
}

.YearMonthDay .select-control .dropdown {
  display: inline;
}

.dropdown-menu {
  min-width: auto !important;
}

.consent-checkbox {
  padding: 20px;
}

.signing-page-main-container {
  width: 100%;
  height: 100%;
}

.pdf-preview-container {
  height: 100%;
}

.view-layout {
  height: 100%;
}

.kba-questions {
  margin-top: 8px;
  font-weight: bold;
}

.kba-border {
  border-top: 1px solid var(--theme-border, #dee2e6);
  height: calc(100vh - 284px) !important;
  height: calc(100svh - 284px) !important;
}

.kba-header {
  padding-top: 35px;
  padding-left: 14%;
  padding-right: 14%;
  color: $primary-font-color;
  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.kba-component .content-header,
.kba-component .content-paragraph,
.kba-component .component-header {
  padding-top: 10px;
  padding-left: 14%;
  padding-right: 14%;
  text-align: left;
  color: $primary-font-color;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;

  input[type="radio"] {
    accent-color: $primary-blue;
    width: 16px;
    height: 16px;
  }
}

.kba-component .content-header {
  line-height: 20px;
}

.kba-component .component-header {
  padding-top: 15px;
}

.kba-button {
  position: absolute;
  right: 30px;
  bottom: 25px;
  width: 122px;
  font-weight: normal !important;
  height: 38px;
}

.react-checkbox-tree:not(.rct-native-display) .rct-checkbox {
  display: none;
}

.btn-bottom-right {
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 10px;
}

.black-border-line {
  border: $multi-step-border;
  margin-top: 33px;
  margin-bottom: 28px;
}

.dib {
  display: inline-block;
}

.width-50 {
  width: 50%;
}

/* Esign page End */

#summary-review {
  height: 100%;
  overflow: auto;
}

.payment-refund-print {
  cursor: pointer;
}

#preparer_message {
  height: 100%;
  padding-bottom: 50px;
}

.preparerMessage {
  height: calc(100% - 50px) !important;
  overflow-y: auto;
  position: relative;
  font-weight: 500;
}

.preparerMessageContainer {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 60px;
  height: 100%;
}

.preparerMessagePrint {
  float: right;

    &:hover,
    &:focus,
    &:active {
        svg path {
            fill: $white;
        }
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
            svg path {
                fill: initial;
            }
        }
    }
}

.reviewTab {
  float: left;
  width: 15%;
  height: 88%;
  border-right: 2px solid darkgray;
  background-color: white;
}

.reviewContainerBody {
  height: 88% !important;
  background-color: #e0e0e0;
  border-right: 2px solid;
}

#review-tab-navbar {
  margin: 10px 0px;
  color: $black;
  font-weight: 400;
  width: auto;
  padding-left: 35px;
  font-size: 16px;
}

.review-left-panel {
  margin: 0;
  padding: 0;
  overflow: unset !important;
}

.review-left-pdf-viewer {
  padding: 0;
}

.reviewContainer {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  width: 96%;
  height: 88%;
  margin-left: 20px;
}

.right-pointer {
  position: relative;
  background-color: $brand-primary-blue-2;
  height: 37px;
  font-weight: 700 !important;
  line-height: 40px;
  vertical-align: middle;
  color: white !important;
  width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

#document-review {
  margin-left: 25px !important;
}

.review-container {
  height: 100%;
  padding: 0;
}

.review-container-body {
  display: flex;
  border-bottom: 2px solid darkgray;
  background: white;
  height: calc(100% - 55px);
}

.review-container-title {
  height: 55px;
  border-bottom: 2px solid darkgray;
  padding: 12px 0px 12px 30px;
  font-weight: bold;
  font-size: x-large;
  background: white;
}

.attachment-viewer {
  padding-top: 20px;
  padding-left: 20px;
}

.attachment-file-viewer {
  height: auto !important;
  margin-top: -5px;
  background: var(--HitBox, rgba(255, 255, 255, 0));
  display: flex;
  justify-content: unset !important;
  padding-left: 0px !important;
  padding-right: 0px !important;

  & > span {
    color: $brand-primary-blue-1;
  }

  &:focus {
    box-shadow: none !important;
  }
}

#attachment-table {
  font-size: larger;
}

.attachment-ZipBtn {
  margin-top: 20px;
}

/* keyframes for the download icon anim */
@keyframes downloadArrow {
  /* 0% and 0.001% keyframes used as a hackish way of having the button frozen on a nice looking frame by default */
  0% {
    margin-top: -7px;
    opacity: 1;
  }

  0.001% {
    margin-top: -15px;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    margin-top: 0;
    opacity: 0;
  }
}

.colorBlue {
  color: #1a8fbf;
}

@media (min-width: 315px) and (max-width: 421px) {
  .esign-div {
    margin-right: 10%;
    padding: 0% 0% 0% 0%;
  }

  body {
    overflow: auto;
  }

  .information-popup {
    left: 0 !important;
  }

  .tax-fee-box {
    width: 96% !important;
  }

  .pay-margin-top-20 {
    margin-top: 10px !important;
  }
}

.myaccount {
  &.myaccountModal .modal-body {
    padding: 30px 17px;
  }

  &_body {
    &-headerMainTitle {
      font-weight: 700;
      line-height: 1;
      color: $primary-font-color;
      margin-bottom: 10px;
    }

    &-headerText,
    &-section1--headerText,
    &-section2--headerText {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      color: $primary-font-color;
      margin-bottom: 4px;
    }

    &-paragraph {
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 10px;
      font-weight: 400;
    }

    &-section1 {
      margin-left: 10px;
    }

    &-section2 {
      &--name {
        margin-top: 26px;
        margin-bottom: 10px;
      }

      &--headerValue {
        color: $brand-primary-blue-2;
        font-weight: 600;
        line-height: 1.4;
      }

      &--headerTitle {
        font-weight: 600;
        color: $primary-font-color;
        margin-bottom: 10px;
        line-height: 1.4;
      }
    }

    .second-signerinfo {
      margin-left: 10px;
    }
  }
}

.myaccount-mobilenumber {
  .modal-header {
    background-color: $brand-primary-blue-1;
    border: 1px solid $brand-primary-blue-1;

    .closeIcon {
      svg path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .modal-title {
    color: $white;
    font-weight: 500;
  }
}

.myaccount-text {
  font-size: 12px;
}

.myaccount-text span {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: $primary-font-color;
}

.myaccount-text input {
  width: 100%;
  margin-top: 5px;
  padding: 4px 8px;
  border: none;
  border-radius: 2px;
  border: 1px solid #898d91;
  background-color: $white;
  color: $primary-font-color;
  font-size: 16px;
  font-family: $font-family;
}

.myaccount-text input:focus,
.myaccount-text input:active {
  border: 1px solid #898d91;
}

.myaccount-changenumber {
  padding-right: 10px;
  padding-left: 4px;
}

.myaccount-countrycode {
  padding-right: 0px;
}

.accesscode {
  z-index: 1061;
}

.accesscode.footer button {
  margin: 0 5px;
}

.pending {
  min-height: 50px;
  width: 90% !important;
  margin: 10px auto 0 auto;
}

.alert {
  padding: 14px 16px;
  border-radius: 4px;
  width: 100%;

  display: flex;
  align-items: center;

  &-content {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &-message {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $primary-font-color;
    margin-left: 11px;
  }
}

.alert-warning {
  border-left: 5px solid #d69f38;
  background: #fbf5eb;
}

.imageBorder {
  border: 1px solid #88c656;
  border-radius: 4px;
  opacity: 0.7;
  transition: 0.3s;
  background-color: #fafafa !important;
  cursor: pointer;
}

.imageBorder:hover {
  opacity: 1;
}

.send-reminder {
  color: $brand-primary-blue-1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-decoration: underline;

  svg {
    margin-right: 8px;
  }
}

.send-reminder:hover {
  cursor: pointer;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.imageSizing {
  width: 50%;
  padding: 4%;
  margin: 10%;
}

.complete-wizard-welcome-intro {
  display: inline-flex !important;
  margin: 0px 30px 15px 0px;
  padding: 0% 0% 0% 18%;
  width: 100%;
}

.margin-left-5 {
  margin-left: 5%;
}

/*Custom Checkbox START*/
/* Customize the label (the container) */
.consent-checkbox {
  display: block;
  position: relative;
  padding-left: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.consent-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  top: 20px;
  left: 20px;
  z-index: 99;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid var(--control-border-color);
  border-radius: 4px !important;
}

/* On mouse-over, add a grey background color */
.consent-checkbox input:hover ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.consent-checkbox input:checked ~ .checkmark {
  background-color: var(--control-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.consent-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.consent-checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #9fe350;
  border: solid var(--control-border-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Custom Checkbox END*/

/*K1 distribution start*/

.row-selected {
  background-color: #caedff !important;
}

.btn-white {
  background: #fafafa;
  border: 1px #e0e0e0 solid;
  color: #303641;
  margin-left: 8px;
}

.btn-white:hover {
  background: #ebebeb;
}

.padT10 {
  padding-top: 10px;
}

.padT5 {
  padding-top: 5px;
}

.marL10 {
  margin-left: 10px;
}

.marB10 {
  margin-bottom: 10px;
}

.tpGRPagePadding {
  padding: 2rem 1.5rem 1.5rem 1.5rem !important;
}

.content-wrapper-2 {
  /*margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    max-width: 80% !important;*/
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}

.content-wrapper-2 .content-select-k1-buttons {
  margin: 10% 0% 0% 0%;
  width: 100%;
  padding: 0% 15% 0% 0%;
  display: inline-flex;
  height: 220px;
}

.content-wrapper-2 .content-select-k1-buttons .k1-electronic-div {
  width: 50%;
  padding-left: 19%;
}

.content-wrapper-2
  .content-select-k1-buttons
  .k1-electronic-div
  .imageBorderK1Electronic {
  color: #1a8fbf;
  margin: 55px;
}

.content-wrapper-2 .content-select-k1-buttons .k1-electronic-div .k1PageSpan1 {
  margin: 1% 0% 0% 1%;
  position: fixed;
  display: inline-flex;
}

.content-wrapper-2 .content-select-k1-buttons .k1-manual-div {
  width: 27%;
  margin: 0% 0% 0% 20%;
  padding: 0% 0% 0% 4%;
}

.content-wrapper-2
  .content-select-k1-buttons
  .k1-manual-div
  .imageBorderK1Manual {
  color: #1a8fbf;
  margin: 45px;
}

.content-wrapper-2 .content-select-k1-buttons .k1-manual-div .k1PageSpan2 {
  position: fixed;
  margin: 1% 0% 0% 2%;
}

.imageBorderK1Select {
  border: 2px solid #88c656;
  border-radius: 16px;
  opacity: 0.7;
  transition: 0.3s;
  background-color: #d8d8d8 !important;
}

.imageBorderK1Select:hover {
  opacity: 1;
}

.k1PartnerPopup {
  .modal-header {
    background-color: $brand-primary-blue-1;
    border: 1px solid $brand-primary-blue-1;

    .closeIcon {
      svg path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .modal-body {
    height: 500px;
    overflow-y: auto;
    position: relative;
    padding: 15px;
    padding-left: 20px;
  }

  .modal-title {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
  }
}

/* Pay Step - START */

.pay-content-left > div {
  /*padding: 10px;*/
  background-color: #fff;
  border-radius: 5px;
  position: relative;
}

.pay-page-stepper {
  border: 1px #e8e8e8;
  border-style: solid none;
}

/*.pay-content-right > div {
    padding: 13px 20px 0px 20px;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}*/

.pay-content-top > div {
  padding: 10px 21px 10px 20px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  /*width: 93%;*/
}

.pay-content-left .tax-payment-container {
  height: 100%;
  padding: 19px 21px 38px 20px;
}

#pay-tab-navbar {
  margin: 20px 0px;
  color: #88c656;
  font-weight: 700;
  width: auto;
  padding-left: 10px;
  font-size: 14px;
}

.pay-right-pointer {
  position: relative;
  background-color: #0973ba;
  /*background: var(--headerBgColor);*/
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
  vertical-align: middle;
  color: white !important;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  width: 100% !important;
  border-radius: 6px;
}

.pay-arrow-right:before {
  content: "";
  position: absolute;
  right: -30px;
  top: -2px;
  border-top: 26px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 25px solid transparent;
  width: 31px;
}

.pay-arrow-right:after {
  content: "";
  position: absolute;
  right: -28px;
  top: 0px;
  border-top: 24px solid transparent;
  border-bottom: 16px solid transparent;
  border-left: 23px solid #0973ba;
  /*border-left: 23px solid var(--headerBgColor);*/
  width: 32px;
  color: white;
  z-index: 9;
}

.pay-currencytext {
  text-align: right;
  padding-right: 15px;
}

/* Pay Step - END */

.k1PartnerPopup .modal-footer {
  padding: 15px;
  text-align: right;
}

.k1PartnerPopup-text {
  padding: 10px;
  font-size: 12px;
}

.displayFlex {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.displayFlex3 {
  flex: 3;
}

@media (min-width: 311px) and (max-width: 800px) {
  .imageBorderK1Electronic {
    margin: 5px !important;
  }

  .mydownload-popup-icon {
    margin-top: -8px;
  }

  .imageBorderK1Select {
    width: 100px;
  }

  .imageBorderK1Manual {
    margin: 5px !important;
  }

  .k1PageSpan1 {
    margin: -18%;
    position: absolute !important;
    display: inline-table;
    padding: 5% 0% 0% 3%;
  }

  .k1PageSpan2 {
    position: absolute !important;
    margin: -17%;
    padding: 5% 0% 0% 10%;
    display: inline-table;
  }

  .displayFlex {
    flex-direction: column;
  }
}

/*K1 distribution end*/

/* Download Page - START */

.download-documents {
  &-layout {
    .step-layout-header {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 8px;

      .row {
        display: unset;
        margin: 0;
      }

      .float-right {
        float: unset !important;
      }

      .pr-5 {
        padding-right: 0 !important;
      }

      .custom-step-layout-button {
        margin-top: 1px;
        width: 175px !important;
        height: 38px !important;
      }
    }

    .step-layout-content {
      height: calc(100% - 68px) !important;

      .steps-footer {
        padding: 26px 34px;
      }
    }
  }

  &-screen {
    &_title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
    }

    &_paragraph {
      margin-top: 11px;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
    }

    &_container {
      margin: 24px 33px;
    }
  }
}

.inline-block-container {
  display: inline-block;
  vertical-align: top;
}

.download-title {
  font-size: 20px;
  font-weight: 600;
}

.download-list {
  margin-top: 42px;
}

.download-list,
.download-all-body {
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: $primary-font-color;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 8px;

    li {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: $primary-font-color;

      & svg {
        margin-left: 5px;
        margin-bottom: 3px;

        &:first-child {
          cursor: pointer;
        }
      }
    }
  }

  &_svg {
    path {
      fill: $brand-primary-blue-1;
    }
  }
}

.download-all-body {
  ul {
    margin-top: 0;

    li {
      display: flex;
      justify-content: space-between;

      & svg {
        margin: 0;
        align-self: center;
      }
    }
  }

  .ellipsisText {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.download-all-modal {
  .modal-dialog {
    min-width: 414px;
    width: 414px;
  }

  .modal-body {
    padding: 26px 49px 26px 16px;
  }
}

.download-container {
  min-height: 72vh;
  margin-top: 50px;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.download-footer button {
  font-size: 14px;
  font-weight: bold;
  border-radius: 50px !important;
}

/* Download Page - END */
.information-popup {
  position: absolute;
  top: 45%;
  left: 40%;
}

/*Screen share START*/
.vertical-center {
  margin: 0;
  height: 5em;
  position: absolute;
  left: 50%;
  bottom: 2%;
}

.vertical-center .stop {
  height: 5em;
  width: 5em;
}

.vertical-center .stop.disabled {
  fill-opacity: 0.5;
}

.screen.partner {
  width: 98vw;
  border: 1px solid black;
  box-shadow: 2px 2px 3px black;
  margin: 5px;
  height: 97vh;
}

/*Screen share END*/

/* TaxReturn Status Page css Start*/
.draftstatus-page {
  height: calc(100svh - 250px) !important;
}

.draftstatus-completed-padding{
  padding-right: 30% !important;
}

.draftstatus-reviewed-padding{
  padding-right: 20% !important;
}

@media only screen and (max-width: 1024px ) {
  .draftstatus-completed-padding{
    padding-right: 0% !important;
  }
  
  .draftstatus-reviewed-padding{
    padding-right: 0% !important;
  }
}

.draftstatus-message{
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
}

.draftstatus-cpa-text {
  color: var(--body-text-body-color, #212529);
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.draftstatus-closewindow-text {
  color: var(--body-text-body-color, #212529);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.draftstatus-companylogo {
  margin-top: 10px;
  width: 240px;
  height: 45px;
}

.draft-status-container{
  margin-top: 110px;
}
/* TaxReturn Status Page css End*/

.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 315px;
}

.phoneNumeberTextBox {
  font-size: 12px;
  height: 38px;
  border-radius: 4px !important;
  width: 70%;
  margin-left: 5px;
}

.cc-CountryCode.phoneNumberSelect {
  padding: 0px 0px 0px 15px;
  font-size: 12px;
}

.height-35 {
  height: 35px !important;
}

.divMailId {
  padding-top: 2px;
  padding-right: 0px;
}

.inputMailId {
  font-size: 12px;
  height: 37px;
  margin-top: -2px;
}

.divPhoneNumber {
  padding-left: 0px;
  padding-top: 2px;
  padding-right: 0px;
}

.height-38 {
  height: 38px !important;
}

/*Tutorial START*/
.tutorial-header {
  font-size: 16px;
  color: #0973ba;
  /*color: var(--headerBgColor);*/
  font-weight: 500;
}

.tutorial-body {
  margin: 12px 0px;
}

.tutorial-checkbox {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.tutorial-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  top: 16px;
  z-index: 99;
}

/* Create a custom checkbox */
.tutorial-checkmark {
  position: absolute;
  top: 16px;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid var(--control-border-color);
  border-radius: 4px !important;
}

/* On mouse-over, add a grey background color */
.tutorial-checkbox input:hover ~ .tutorial-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.tutorial-checkbox input:checked ~ .tutorial-checkmark {
  background-color: var(--control-background-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.tutorial-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.tutorial-checkbox input:checked ~ .tutorial-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.tutorial-checkbox .tutorial-checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #9fe350;
  border: solid var(--control-border-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tutorial-checkbox-label {
  padding-left: 25px;
  margin-top: 15px;
}

/*Tutorial End*/

.attachment-tab-container {
  max-height: calc(100% - 180px);
  padding: 0 40px;
  padding-top: 37px;
  /*overflow-y: auto;
    overflow-x: hidden;*/

  .table thead th {
    border: none;
  }

  .table tbody tr {
    border-bottom: 1px solid #c4c6c8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.attachment-download-btn-container {
  position: absolute;
  border: 0 !important;
}

.attachment-tab-container .react-bs-container-body {
  max-height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.attachment-tab-container .react-bs-container-body svg {
  margin-right: 8px;
}

.invalid-container {
  background-color: #f0f0f0;
  height: 100%;
}

.invalid-header {
  background-color: #0973ba;
  height: 55px;
  padding: 6px;
}

.error-page {
  padding-left: 16% !important;
  padding-right: 16% !important;
  overflow: auto;
}

.error-page-container {
  height: calc(100vh - 85px);
  height: calc(100svh - 85px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
}

.error-page-container .message {
  color: $primary-font-color;
  text-align: center;
  font-family: $font-family;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.icon---completed-signature {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.cc-recipient-input-details {
  border: 2px solid var(--control-border-color) !important;
  background-color: var(--control-background-color);
  border-color: var(--control-border-color);
  border-radius: 4px;
  font-family: inherit !important;
  height: 40px !important;
}

.chip {
  border: 1px solid #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 3px 0;
  background: #007bff;
  color: aliceblue;
}

@media (min-width: 800px) {
  #ccRecipient-modal {
    min-width: 800px;
  }

  #attachmentInstructionPopup .modal-content {
    min-width: 100% !important;
  }
}

.cc-title {
  border: 1px solid #d4d5d6;
  padding: 5px;
  margin-top: 11px;
  text-decoration: underline;
}

.ssr-default-email {
  color: #0000ff;
  text-decoration: underline;
}

#ccRecipient-modal .modal-content .modal-header {
  height: 40px !important;
  background: #0973ba;
  padding-bottom: 45px !important;
}

#ccRecipient-modal .modal-content .modal-body {
  max-height: 540px;
  overflow-y: scroll;
}

.cc-modal-cancel.rounded {
  border: 3px solid #008cee !important;
  border-color: #008cee !important;
}

#attachment-instruction-modal {
  margin-left: 380px !important;
}

#attachment-instruction-modal .modal-content .modal-header {
  height: 40px !important;
  background: #0973ba;
  padding-bottom: 45px !important;
}

#attachment-instruction-modal .modal-content .modal-body {
  max-height: 440px;
  overflow-y: scroll;
}

.cc-doc-checkbox {
  display: inline-flex;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cc-doc-checkbox input {
  opacity: 0;
  cursor: pointer;
  height: 16px;
  width: 16px;
  left: 20px;
  z-index: 99;
}

.doc-selector {
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid var(--control-border-color);
  border-radius: 4px !important;
}

.cc-doc-checkbox input:hover ~ .doc-selector {
  background-color: #ccc;
}

.cc-doc-checkbox input:checked ~ .doc-selector {
  background-color: var(--control-background-color);
}

.doc-selector:after {
  content: "";
  position: absolute;
  display: none;
}

.cc-doc-checkbox input:checked ~ .doc-selector:after {
  display: block;
}

.cc-doc-checkbox .doc-selector:after {
  left: 5px;
  width: 5px;
  height: 12px;
  border: solid #9fe350;
  border: solid var(--control-border-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.email-document-btn {
  border-radius: 18px !important;
  color: #fff;
}

.ccRecipient-input-titles {
  border: 2px solid #d4d5d6;
  height: 40px;
  margin: 6px 0px;
  background: lightgray;
  display: flex;
}

.ccRecipient-title-highlight {
  padding: 6px 6px 9px 6px;
  font-weight: 600;
}

.cc-Email {
  flex-basis: 180px;
}

.cc-CountryCode {
  flex-basis: 116px;
}

.cc-MobileNumber {
  flex-basis: 125px;
}

.cc-MobileNumber .phoneNumeberTextBox {
  width: 100% !important;
}

.float-right {
  float: right;
}

.cc-entered-email {
  padding-left: 30px;
}

.cc-entered-num {
  padding-left: 17px;
}

#closeCCRecipientPopover {
  opacity: 1;
  color: black;
  font-weight: 600;
  margin-left: 54%;
  margin-top: 3%;
}

#closeCCRecipientPopover .popover-header {
  background-color: #f2f2f2 !important;
  color: black;
  font-size: 13px;
}

.ccRecipientPopover-confirm-btn {
  border-radius: 22px !important;
  background-color: #f2f2f2 !important;
  border: 1px solid #f2f2f2;
  width: 100%;
  padding: 2px;
  margin-top: 10px;
}

.ccrecipient-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 0px 12px 0px;

  &--fileName {
    flex: 3;
  }

  &--downloadBtn {
    flex: 1;

    .downloadText {
      color: #05386b;
    }

    svg path {
      fill: #05386b;
    }
  }
}

.ccdownload-modal {
  max-width: 815px;
  margin-left: auto;
  margin-right: auto;

  &-subheader {
    font-size: 14px;
    font-weight: 700;
    padding: 33px 0px 7px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--fileName {
      flex: 3;
    }

    &--actions {
      flex: 1;
      padding-left: 30px;
    }
  }
}

.ccdownload-modal ul {
  margin: 0px;
  padding: 0px;
}

.relativePosition-top-2 {
  position: relative;
  top: -2px;
}

.ccdownload-header {
  font-size: 18px;
  font-weight: 700;
  margin-top: 25px;
  line-height: 21.6px;
}

.download-foo-btn {
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  text-align: right;
}

.ccdownloadfiles {
  max-height: 400px;
  overflow: auto;
}

.ccdownload-list {
  list-style-type: none;
  font-weight: 400;
  margin-left: 7px;
  border-bottom: 1px solid #c4c6c8;
}

.cc-docList-separator {
  margin: 0px 24px 8px -24px;
  border-top: 2px solid #d4d5d6;
}

.cc-details-separator {
  margin-top: 13px;
  border-top: 2px solid #d4d5d6;
}

.cc-remove-recipient {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
  margin-top: 9px;
  margin-left: 13px;
}

.cc-add-recipient {
  font-size: 20px;
  cursor: pointer;
  color: var(--control-border-color);
  margin: 0px 7px 6px 15px;
}

div[id^="ccRecipientCountryCode"] {
  width: 95px;
}

div[id^="ccRecipientCountryCode"] div[class$="-control"] {
  border: 2px solid var(--control-border-color) !important;
  background-color: var(--control-background-color);
  border-color: var(--control-border-color);
}

input[id^="ccMobile"] {
  border: 2px solid #d4d5d6;
}

/*Completed Summary Page Style Starts here*/
.button---download-documents {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.button---signed-documents {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.button---tax-payments {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.button---Distribute-K1 {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.button---PaperFiledReturn {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.button---invoice {
  width: auto;
  height: 55px;
  object-fit: contain;
}

.button---review-documents,
.button---pay-tax-prep-fee {
  width: auto;
  height: 55px;
  object-fit: contain;
}

/*Completed Summary Page Style Ends here*/

/*Add Voucher Payemnt style starts here*/
.padTLR {
  padding: 10px 50px 0px 50px;
}

.voucherPayementLinkContainer {
  margin-top: 16px;
  font-weight: 600;
  margin-bottom: 9px;
}

.voucherPayementLink {
  color: var(--theme-navy, $brand-primary-blue-1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline !important;
  cursor: pointer !important;
}

.input-dollar {
  position: relative;
}

.input-dollar.left input {
  padding-left: 18px;
}

.input-dollar:before {
  position: absolute;
  top: 0;
  content: "";
}

.input-dollar.left:before {
  left: 5px;
}

.input-group {
  width: 100%;
}

@media (min-width: 768px) {
  .input-group {
    width: auto;
  }
}

.input-group.date > input[type="text"] {
  z-index: 0 !important;
}

.input-group-addon {
  padding: 9px;
  font-size: inherit;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 0px !important;
}

.btn-paid {
  min-width: 0 !important;
  border: 2px solid #89c05a !important;
  background-color: #89c05a !important;
  color: white !important;
  min-width: 70px !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.voucher-paid-icon {
  position: absolute;
  top: -5%;
  left: 76%;
  color: #88c656;
  padding: 2px;
  text-align: center;
  background-color: #ffffff;
  font-size: 16px;
}

.voucher-paid-icon-placing {
  top: -10px;
  width: min-content;
  border-radius: 20px;
  left: 100px;
}

.voucher-pay-datepicker .react-datepicker__close-icon {
  background-color: #eeeeee !important;
  padding: 7px !important;
}

.voucher-pay-datepicker .react-datepicker__close-icon::after {
  background-color: #eeeeee !important;
  color: #333 !important;
  font-size: 20px !important;
}

.voucher-pay-datepicker .react-datepicker-wrapper {
  width: 83%;
}

.inputTextAllignRight {
  text-align: right;
  font-size: inherit;
}

.mandatory-star {
  font-size: 7px;
  color: #c50505;
  position: relative;
  bottom: 5px;
  left: 5px;
}

.calenderStyling {
  position: absolute;
  z-index: 1;
  left: 83%;
  font-size: 15px !important;
}

.invoice-payment-heading {
  padding: 10px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.invoice-payment-heading h3 {
  margin-bottom: 0px;
}

.invoice-payment-datepicker .react-datepicker-wrapper {
  width: 86% !important;
}

.invoice-payment-datepicker .calenderStyling {
  position: absolute;
  z-index: 1;
  left: 86% !important;
  font-size: 15px !important;
}

#ddlPaymentMode .css-26l3qy-menu {
  margin-top: 0px !important;
}

.input-fontSize {
  font-size: inherit;
}

.pointer-Stylling {
  cursor: default !important;
}

/*Add Voucher Payemnt style ends here*/

.tbl-controller-dashboard .react-bs-container-body {
  overflow: inherit;
}

.tbl-controller-dashboard .dropdown-toggle:after {
  content: none;
}

.tbl-controller-dashboard .react-datepicker__close-icon::after {
  background-color: #dddddd !important;
}

.table-text-sort {
  display: inline-block;
  width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: -4px;
}

.taxReturnActionClass,
.nextPaymentDueClass,
.invoiceStatusClass,
.sort-column[title="Tax Year"],
.sort-column[title="Signature Status"] {
  overflow: inherit !important;
}

#controller-table-search {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  border-radius: 5px !important;
}

#dropdown-tax-return {
  font-size: large;
  color: darkgreen;
}

#dd-payment-due,
#dd-invoice-status {
  cursor: pointer;
}

#header-dashboard {
  min-height: inherit;
}

.wrapper input[type="text"] {
  position: relative;
}

input {
  font-family: $font-family;
}

.dashboatd-title {
  padding: 0px 5px 0px 5px;
}

.send-email-checkbox {
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: 3px;
  accent-color: $brand-primary-blue-2;
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15));
  background: var(--gray-white, #fff);
  margin-top: 7px;
}

.mce-statusbar {
  display: none !important;
}

.delegatee-input-field {
  align-items: center;
  border: 3px solid #d4d5d6;
  padding: 4px;
  max-height: 100px;
  width: 100%;
  display: flex;
}

.tpGRHeaderTitleSearch {
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h3 {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    color: #212529;
  }

  .tpGRHeaderSearch {
    width: 357px;
    height: 38px;
    border: 1px solid #898d91;
    border-radius: 4px;
    padding: 0px !important;
    display: flex;

    input {
      width: 315px;
      height: 35px;
      border: 0px !important;
      padding: 6px 12px;
      border-radius: 0px !important;
      font-family: Mulish !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: #212529;
      outline: none !important;
    }

    input:focus {
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .tpGRHeaderSearchIcon {
      width: 39px;
      border-left: 1px solid #898d91;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 800px) {
      width: 300px;
    }
  }
}

.tpGRHeaderDescButtons {
  padding: 0px !important;
  margin: 0px !important;
  display: flex !important;
  justify-content: space-between !important;

  .tpGRHeaderDesc {
    font-family: Mulish !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    padding: 0px !important;
    margin-top: -12px;
    color: #212529;
    max-width: 400px;

    @media (max-width: 800px) {
      max-width: 250px;
    }
  }

  .tpGRHeaderButtons {
    padding: 0px !important;
    margin-top: 7px;
    display: flex;

    .tpGRHeaderButtonRefresh {
      margin: 0px !important;
      width: 38px;
      min-width: 0px !important;
      height: 38px;

      &:focus {
        svg path {
          fill: $white;
        }
      }
    }

    .tpGRHeaderButtonClearFilters {
      margin: 0px 0px 0px 1rem !important;

      &:focus {
        svg path {
          fill: $white;
        }
      }
    }
  }
}

.tpGRTable {
  padding: 1rem 0 0 0 !important;

  .react-bs-container-header {
    table {
      th {
        border-top: 0px;
        border-bottom: 1px solid #898d91;
        padding: 0 1.2rem 0.75rem 0 !important;
        font-family: Mulish;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;

        i {
          color: #6b7075;
        }

        input[type="text"] {
          height: 31px;
          padding: 4px 8px;
          border: 1px solid #898d91;
          border-radius: 3.2px !important;
          outline: none;
        }

        input[type="text"]:focus {
          outline: none !important;
          -webkit-box-shadow: none;
          box-shadow: none;
        }

        .taxYearTH__control {
          height: 31px;
          min-height: 0px !important;
          border: 1px solid #898d91;
          border-radius: 3.2px;
          padding: 4px 4px 4px 8px;
          display: flex;
          flex-wrap: nowrap !important;
          outline: none !important;
          box-shadow: none !important;

          .taxYearTH__value-container {
            padding: 0px !important;
          }

          .taxYearTH__indicators {
            .taxYearTH__indicator-separator {
              width: 0px;
            }

            .taxYearTH__indicator {
              padding: 0 4px !important;
              cursor: pointer;
            }

            .taxYearTH__clear-indicator {
              border-right: 1px solid hsl(0, 0%, 80%);
              height: 14px;
              display: flex;
              align-items: center;
            }
          }
        }

        .signatureStatusTH__control {
          height: 31px;
          min-height: 0px !important;
          border: 1px solid #898d91;
          border-radius: 3.2px;
          padding: 4px 4px 4px 8px;
          display: flex;
          flex-wrap: nowrap !important;
          outline: none !important;
          box-shadow: none !important;

          .signatureStatusTH__value-container {
            padding: 0px !important;
          }

          .signatureStatusTH__indicators {
            .signatureStatusTH__indicator-separator {
              width: 0px;
            }

            .signatureStatusTH__indicator {
              padding: 0 4px !important;
              cursor: pointer;
            }

            .signatureStatusTH__clear-indicator {
              border-right: 1px solid hsl(0, 0%, 80%);
              height: 14px;
              display: flex;
              align-items: center;
            }
          }
        }

        select {
          height: 31px;
          padding: 4px 8px;
          border: 1px solid #898d91;
          border-radius: 3.2px !important;
          outline: none;
          color: #212529;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-size: 12px 13px;
          background-position: right 0px top 50%;
          background-repeat: no-repeat;
          background-origin: content-box;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.23517 3.98468C1.27 3.94976 1.31139 3.92205 1.35695 3.90315C1.4025 3.88424 1.45134 3.87451 1.50067 3.87451C1.55 3.87451 1.59884 3.88424 1.6444 3.90315C1.68995 3.92205 1.73134 3.94976 1.76617 3.98468L6.00067 8.21993L10.2352 3.98468C10.27 3.94982 10.3114 3.92216 10.357 3.90329C10.4025 3.88442 10.4514 3.87471 10.5007 3.87471C10.55 3.87471 10.5988 3.88442 10.6444 3.90329C10.6899 3.92216 10.7313 3.94982 10.7662 3.98468C10.801 4.01955 10.8287 4.06094 10.8476 4.10649C10.8664 4.15205 10.8761 4.20087 10.8761 4.25018C10.8761 4.29949 10.8664 4.34832 10.8476 4.39387C10.8287 4.43942 10.801 4.48082 10.7662 4.51568L6.26617 9.01568C6.23134 9.0506 6.18995 9.07831 6.14439 9.09722C6.09884 9.11612 6.05 9.12585 6.00067 9.12585C5.95134 9.12585 5.9025 9.11612 5.85695 9.09722C5.81139 9.07831 5.77 9.0506 5.73517 9.01568L1.23517 4.51568C1.20025 4.48085 1.17254 4.43947 1.15364 4.39391C1.13473 4.34835 1.125 4.29951 1.125 4.25018C1.125 4.20086 1.13473 4.15202 1.15364 4.10646C1.17254 4.0609 1.20025 4.01952 1.23517 3.98468Z' fill='%23898D91'/%3E%3C/svg%3E");

          option:first-child {
            display: none;
          }
        }

        .react-datepicker-popper[data-placement^="bottom"] {
          .tpGRCalendar {
            margin-top: -12px;
          }
        }

        .react-datepicker-popper[data-placement^="top"] {
          .tpGRCalendar {
            margin-top: 24px;
          }
        }

        select:focus {
          outline: none !important;
          -webkit-box-shadow: none;
          box-shadow: none;
        }

        & > div {
          margin-top: 0.2rem;
        }

        &:last-child {
          vertical-align: top;
        }
      }
    }
  }

  .react-bs-container-body {
    .tpGRTableBody {
      .tpGRTableRow {
        border-top: 1px solid #c4c6c8;

        &:first-child {
          border-top: 0px !important;
        }

        td {
          border-top: 0px !important;
          padding: 0.5rem 0.75rem 0.5rem 2px !important;
        }

        .taxReturnActionClass {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 2px !important;

          .tpGRActionsItem {
            background: none;
            border: none;
            color: #05386b;
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            padding: 0px !important;
          }

          .tpGRActionsItem:disabled {
            color: #05386b40;
          }

          .tpGROpenButton {
            width: 40px !important;
            min-width: 40px !important;
            min-height: 24px !important;
          }

          .tpGRDownloadButton {
            width: 77px !important;
            min-width: 77px !important;
            min-height: 24px !important;
          }

          .tpGREmailButton {
            width: 130px !important;
            min-width: 130px !important;
            min-height: 24px !important;
          }

          .tpGRActionsSeparator {
            color: #21252940;
          }

          .tpControllerMoreActions button {
            min-width: 0px;
            background: #ffffff00;
            border: 0px;
            padding: 0 3px 0 0;
          }

          .dropdown-menu {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            padding: 8px 0px;
            min-width: 0px;
            cursor: default;

            li {
              padding: 4px 1rem;
              line-height: 150%;
              cursor: pointer;
            }

            li:hover {
              background-color: #00000015;
            }

            .disabledItem {
              opacity: 0.6;
              cursor: not-allowed;
            }
          }
        }
      }

      td {
        border-top: 0px !important;
      }
    }
  }
}

.taxYearTH__menu {
  margin: 0px 0px 0px 0px !important;
  box-shadow: none !important;

  .taxYearTH__menu-list {
    border-top: 0px;
    border-right: 1px solid #898d91;
    border-bottom: 1px solid #898d91;
    border-left: 1px solid #898d91;
    border-bottom-left-radius: 3.2px !important;
    border-bottom-right-radius: 3.2px !important;
    scrollbar-width: thin;

    div {
      padding: 0px !important;

      .taxYearTH__option {
        padding: 0.3rem 0.5rem !important;
        line-height: 21px;

        span {
          margin-left: 0.5rem;
          font-family: Mulish;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .taxYearTH__menu-list::-webkit-scrollbar:vertical {
    width: 0.3rem;
  }
}

.signatureStatusTH__menu {
  margin: 0px 0px 0px 0px !important;
  box-shadow: none !important;

  .signatureStatusTH__menu-list {
    border-top: 0px;
    border-right: 1px solid #898d91;
    border-bottom: 1px solid #898d91;
    border-left: 1px solid #898d91;
    border-bottom-left-radius: 3.2px !important;
    border-bottom-right-radius: 3.2px !important;
    scrollbar-width: thin;

    div {
      padding: 0px !important;

      .signatureStatusTH__option {
        padding: 0.3rem 0.5rem !important;

        span {
          margin-left: 0.5rem;
          font-family: Mulish;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }
    }
  }

  .signatureStatusTH__menu-list::-webkit-scrollbar:vertical {
    width: 0.3rem;
  }
}

.tpGRSigningModal {
  .modal-body {
    .signModalDiv {
      display: inline-block;
      width: 100%;
      padding-bottom: 0.5rem;
      max-height: 70vh;
      overflow-y: auto;

      .headerDiv {
        display: flex;
        border-bottom: 2px solid #212529;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        .emailHeaderDiv {
          width: 58%;
          padding: 0 0 0.5rem 7px;
        }

        .stateHeaderDiv {
          width: 21%;
          padding: 0 0 0.5rem 0;
        }

        .dateHeaderDiv {
          width: 21%;
          padding: 0 7px 0.5rem 0;
        }
      }

      .valueDiv {
        display: flex;
        border-bottom: 1px solid #c4c6c8;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        .emailValueDiv {
          width: 58%;
          padding: 0.5rem 0 0.5rem 7px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .stateValueDiv {
          width: 21%;
          padding: 0.5rem 0 0.5rem 0;
        }

        .dateValueDiv {
          width: 21%;
          padding: 0.5rem 7px 0.5rem 0;
        }
      }

      .valueDiv:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }
}

.tpGRDelegationModal {
  .modal-body {
    .tpGRBodyText {
      padding-left: 0.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

#controller-table-search::placeholder,
/* Chrome, Firefox, Opera, Safari 10.1+ */
#controller-table-search:-ms-input-placeholder,
/* Internet Explorer 10-11 */
#controller-table-search::-ms-input-placeholder

/* Microsoft Edge */ {
  color: #6db685;
  opacity: 1;
  /* Firefox */
}

.custom-step-layout-button {
  width: 150px !important;
  height: auto !important;
  margin-top: -5px;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

    &:hover,
    &:focus,
    &:active {
        svg path {
            fill: $white;
        }
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
            svg path {
                fill: initial;
            }
        }
    }
}

#review-pdf-viewer #awesome-pdf-viewer .main {
  height: calc(85vh - 132px) !important;
}

#awesome-pdf-viewer {
  border: none !important;
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  border-right: 3px solid $brand-primary-blue-2 !important;
}

.fa-btn-icon {
  padding: 4px 0;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
  font-size: 12px;
}

.custom-multiselect-label {
  margin-left: 3px;
  vertical-align: baseline;
  position: relative;
  bottom: 3px;
  font-size: 12px;
}

.send-email-checkbox {
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.mce-statusbar {
  display: none !important;
}

.width100 {
  width: 100% !important;
}

#delegatee-signer-country-code div[class$="-control"],
.delegatee-number-field {
  border: 3px solid #d4d5d6;
  border-radius: 4px;
  height: 20px;
}

.font600 {
  font-weight: 600;
}

.center-align {
  justify-content: center;
}

.remarksTextArea {
  border: solid 2px #21a9e1;
  min-height: 100px;
  width: 100%;
  font-size: inherit;
}

.update-delegatee-icon {
  cursor: pointer;
}

.step-sign-complete {
  height: 100%;
  border: 2px solid rgb(9, 115, 186);
  border-radius: 50%;
}

.complete-icon {
  color: rgb(139, 198, 86);
  margin-top: 6px;
  font-size: 33px;
}

div[title="Complete"][class~="active-step"] .step-sign-complete .complete-icon {
  margin-top: 0px;
  transform: translate(6%, 35%);
}

@media (-webkit-device-pixel-ratio: 1) {
  .update-delegatee-icon {
    margin-left: 27px;
  }
}

#paperfile-consent-modal .modal-content {
  width: auto;
}

#paperfile-consent-modal {
  min-width: 648px;
}

#paperfile-consent-modal .modal-content .modal-header {
  height: 40px !important;
  background: #0973ba;
  padding-bottom: 45px !important;
}

#paperfile-consent-modal .modal-content .modal-body {
  max-height: 250px;
  overflow-y: auto;
}

#paperfile-consent-modal .modal-content .modal-footer {
  display: block !important;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  position: relative;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.delegatee-kba-consent-wrapper {
  &-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 48px;
  }

  .delegatee-consent {
    margin-top: 14px;

    &-container {
      padding: 0 40px;
    }

    .content-paragraph {
      line-height: 1.4;
    }

    .date-of-birth {
      margin-top: 32px;

      &-label {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
      }
    }

    .checkbox {
      margin-top: 30px;
      margin-bottom: 48px;

      .ugCheckArea {
        padding: 0;
      }

      ul {
        margin: 0;

        .eachUGCheck {
          margin: 0;

          label {
            color: #151617;
          }
        }
      }
    }
  }

  .flex-container {
    display: flex;
    align-items: center;
    gap: 19px;

    .flex-item {
      margin-bottom: 16px;
    }

    label {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 4px;
    }
  }

  .monthDropdown,
  .dayDropdown,
  .yearDropdown {
    &__single-value {
      padding-bottom: 3px;
    }

    &__menu-list {
      height: 120px !important;
    }
  }

  .yearDropdown {
    &__control {
      width: 100%;
    }

    &__menu {
      width: 100% !important;
    }
  }

  #yearMonthDateDropdown {
    width: 100%;
  }

  .delegatee-kba-consent-dob {
    gap: 10px;
  }
}

.manualSign-pdf-viewer .step-layout-body {
  overflow-y: hidden !important;
}

.manualSign-pdf-viewer .step-layout-header {
  height: 50px !important;
}

.manualSign-pdf-viewer .step-layout-header .custom-button {
  height: 57px !important;
  padding: 5px;
}

.review-manualSign-footer {
  padding: 5px;
  height: 58px;
  margin-left: 15px;
}

.btn-rounded.clearDetails {
  border-radius: 5rem !important;
  color: white !important;
  background-color: #0070c0 !important;
  border-color: #0070c0 !important;
  min-width: 100px !important;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .steps-body.with-all-sections {
    height: calc(100% - 195px) !important;
  }
}

/*
    border-radius: 5rem !important;
    color: white !important;
    background-color: #0070c0 !important;
    border-color: #0070c0 !important;
    min-width: 100px !important;
}
}
}*/

/* welcome page Css*/

.fa-home:before {
  content: "\f015";
}

.welcome-page {
  padding-left: 15%;
  padding-right: 15%;
  margin: auto;
}

.client-details-header {
  margin-top: 25px;
}

.welcome-text {
  /* Display/H6 */
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  /* 48px */
}

.get-started-button {
  width: 276px;
  height: 48px;
  border-radius: 5rem !important;
}

.tax-year-header {
  color: var(--body-text-body-color, #212529);
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
}

.client-name-header {
  color: var(--body-text-body-color, #212529);
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-10 {
  margin-top: 10px;
}

.prepared-by-title {
  color: var(--body-text-body-color, #212529);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}

.prepared-by-img {
  max-width: 133px;
}

.prepared-by-company-name {
  font-size: x-large;
  font-weight: 600;
}

/* welcome page css end*/

/*OTP page start*/

.otp-page {
  padding-left: 16% !important;
  padding-right: 16% !important;
  padding-top: 30px !important;
  overflow: auto;
}

.otp-page-container {
  border-radius: 6px;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
  margin-top: 8vh;
}

.otp-page-container input {
  border: 1px solid #8bc656;
  border-radius: 5px;
  background-color: #f3f9ee;
  width: 80px;
  height: 40px;
  color: #000;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
}

.otp-page-container input {
  width: 160px;
}

.otp-page-container input::-webkit-input-placeholder {
  font-weight: normal;
}

.otp-page-container input::-moz-placeholder {
  font-weight: normal;
}

.otp-page-container input:-ms-input-placeholder {
  font-weight: normal;
}

.otp-page-container input:-o-input-placeholder {
  font-weight: normal;
}

.otp-page-container input[type="radio"] {
  width: 70px;
  height: 20px;
}

.otp-input-control-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.otp-input-control-wrapper .input-container {
  display: flex;
  flex-direction: row;
}

.otp-input-control-wrapper .invalid-feedback {
  bottom: -25px !important;
}

.otp-input-control-wrapper input {
  width: 34px;
  height: 41px;
  border-radius: 2px;
  border: 1px solid var(--gray-500, #6b7075);
  background: #f5f6fb;
  font-size: 32px;
  padding: 0px;
  color: var(--body-text-body-color, #212529);
  text-align: center;
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.otp-input-control-wrapper input:focus-visible,
.otp-input-control-wrapper input:focus,
.otp-input-control-wrapper input:focus-within {
  outline: none;
}

.otp-input-control-wrapper input:not(:last-child) {
  margin-right: 18px;
}

.otp-input-control-wrapper.error input {
  border-color: var(--error-red, #cc4a43);
}

.otp-input-control-wrapper .icon {
  color: var(--error-red, #cc4a43);
  margin-left: 10px;
}

.otp-input-control-wrapper .error-text {
  width: 100%;
  font-size: "14px";
  color: var(--error-red, #cc4a43);
  margin-top: 10px;
}

@media (min-width: 400px) and (max-width: 600px) {
  .otp-input-control-wrapper .invalid-feedback {
    width: 350px !important;
    text-align: center;
  }

  .otp-input-control-wrapper input {
    font-size: 20px !important;
  }
}

.spam-text {
  color: var(--body-text-body-color, #212529);
  text-align: center;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.resend-btn {
  color: var(--body-text-body-color, #212529) !important;
  font-weight: 700 !important;
  text-decoration-line: underline;
  margin: 0 auto;
}

.invalid-feedback {
  display: flex !important;
  word-wrap: break-word;
  color: var(--error-red, #cc4a43);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
}

#btnMFAContinue {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

#btnMFAContinue.disabled,
#btnAuthenticate.disabled {
  background: $disabled-sapphire;
  color: white !important;
  border: none;
}

.otp-page-title {
  color: $primary-font-color;
  text-align: center;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

#btnRequestAccessCode {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* 28.8px */
}

/*OTP page end*/

/*SSN  page start*/

.ssn-page {
  padding-left: 20% !important;
  padding-right: 20% !important;
  height: 100vh;
  height: 100svh;
  overflow: auto;
}

.ssn-page-container {
  border-radius: 6px;
  padding: 10%;
  background-color: #fff;
  z-index: 2;
  height: 100%;
}

.ssn-page-input {
  border-radius: 2px;
  border: 1px solid $input-border-color;
  background: var(--gray-white, #fff);
  font-weight: bold;
  font-size: 16px;
  display: flex;
  width: 147px;
  padding: 8px 12px;
  align-items: center;
  outline: none;
}

.ssn-page-input > input {
  border: none;
  outline: none;
  width: 100%;
}

.ssn-page-input > input::placeholder {
  color: var(--gray-300, #a6a9ac);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.ssn-page-input.error {
  border: 1px solid #cc4a43;
  color: #cc4a43;
}

.ssn-page-taxpayer-header > span {
  color: var(--body-text-body-color, #212529);
  text-align: center;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.ssn-page-taxpayer-header > .name-label {
  color: $brand-primary-blue-1;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.ssn-page-instruction-label {
  color: var(--body-text-body-color, #212529);
  text-align: center;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.ssn-page-submit {
  color: $sapphire-300;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;

  & > .btn.disabled,
  .btn:disabled {
    background: $disabled-sapphire;
    color: $sapphire-300 !important;
    border: none;
  }
}

.ssn-page-error {
  color: #cc4a43;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
}

.ssn-page-container-label-div {
  display: inline-block;
}

.ssn-page-container-input-div {
  display: inline-block;
  vertical-align: bottom;
}

.cursorDisabled {
  cursor: not-allowed;
}

.welcome-page-step-img-btn > div.col-md-6 {
  float: none !important;
}

/*ssn page end*/

.VoucherUpateStyle {
  background-color: #007bff;
  color: white;
  font-size: 10px;
  width: auto;
  padding: 2px 4px 2px 4px;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: inherit;
  font-weight: 800;
  border-left: inherit;
  border-bottom: inherit;
}

.VoucherNewStyle {
  font-size: 12px;
  width: 61px;
  height: 18px;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 0;
  margin-left: 8px;
  margin-top: -19px;
  border: 1px solid var(--HitBox, rgba(255, 255, 255, 0));
  background: var(--warning-200, #f3e2c3);
  color: $primary-font-color;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.VoucherSelectorMain {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.VoucherSelectionTab {
  background: white;
  height: 80vh;
  width: 80vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.VoucherHeader {
  position: absolute;
  top: 14px;
  left: 8px;
}

.VoucherSelectorIcon {
  border: 3px solid #9fe350;
  border-radius: 5px;
  padding: 35px 60px 35px 60px;
}

.VoucherSelectorIcon:hover {
  cursor: pointer;
}

.dollarIconStyle {
  background: white;
  position: absolute;
  right: -1px;
  bottom: -1px;
  color: #0000ff !important;
  font-size: 0.4em !important;
}

.calendarIconStyle {
  position: relative;
  color: #9fe350 !important;
}

.fileIconStyle {
  position: relative;
  color: #9fe350 !important;
  font-size: 9em;
}

.TaxText {
  position: absolute;
  top: 32px;
  left: 22px;
  font-size: 18px;
  font-weight: 800;
  color: blue;
  background: white;
}

.SelectorText {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: blue;
}

.paddingStyle {
  padding-top: 24px;
  padding-bottom: 24px;
}

.secondaryButtonStyle {
  border-radius: 5rem !important;
  color: white !important;
  border-color: #0973ba !important;
  background-color: #0973ba !important;
  min-width: 100px !important;
}

.voucherSelectionTab:hover {
  cursor: pointer;
}

.UpdatedDocumentText {
  visibility: hidden;
  width: auto;
  background-color: #e6e6e6;
  color: black;
  text-align: center;
  padding: 5px 4px;
  position: absolute;
  z-index: 1;
  bottom: -28px;
  left: 0px;
  border: 1px solid black;
  font-weight: 100;
}

.updatedDocumentInfo:hover .UpdatedDocumentText {
  visibility: visible;
}

.DashboardNameStyle {
  overflow: hidden;
  width: 90%;
  text-overflow: ellipsis;
}

.updatedDocumentInfo {
  position: absolute;
  right: 0px;
  top: 1px;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

#download-tax-document-modal {
  max-width: 650px;
}

#download-tax-document-modal .modal-content {
  width: 500px;
}

#download-tax-document-modal .modal-content .modal-header {
  height: 57px !important;
  border-bottom: 1px solid var(--theme-border, #dee2e6);
  background: $white;
  padding: 16px;
  align-items: center;
  gap: 8px;
}

#download-tax-document-modal .modal-content .modal-body {
  max-height: 312px;
  overflow-y: scroll;
  align-items: flex-start;
  gap: 2px;
}

.download-tax-document-modal-footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.download-tax-document-modal-footer * {
  flex: 1;
}

.download-tax-document-modal-button {
  display: flex;
  max-width: 208px;
}

.download-tax-document-modal-button * {
  max-height: 38px;
}

@media (max-width: 500px) {
  .download-tax-document-modal-footer {
    flex-direction: column;
  }

  .download-all-document-button {
    display: flex;
    max-height: 38px;
  }
}

.cc-choose-docs {
  color: $primary-font-color;
  font-family: $font-family;
  font-size: $font-lg;
  font-weight: bold;
}

.ugCheckArea {
  padding: 2px;
  overflow-y: auto;
  scrollbar-width: thin;

  .eachUGCheck {
    list-style: none;
    display: flex;
    align-items: center;
    height: 1.5rem;
    margin: 0.5rem 0;

    .ugCheckbox {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15));
      margin-right: 8px;
    }

    input[type="checkbox"] {
      accent-color: $primary-blue;
    }

    label {
      color: $primary-font-color;
      font-family: $font-family;
      font-size: $font-lg;
      padding-top: 8px;
      font-weight: 400;
    }
  }
}

.ccrecipient-modal-footer-content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .ccrecipient-modal-body-content {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .delegatee-textbox {
    margin-top: 10px;
  }

  #delegatee-signer-country-code {
    margin: 0px 6px;
  }
}

.review-step-container {
  min-width: 100% !important;
}

@media screen and (orientation: portrait) {
  .summary-step-container {
    display: -webkit-flex;
    -webkit-justify-content: center;
  }

  .completed-summary-step-container {
    display: -webkit-flex;
    -webkit-justify-content: center;
  }
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.flex-align-center {
  display: flex;
  justify-content: center;
}

.disable-pointer-event {
  pointer-events: none !important;
}

.opacityPoint4 {
  opacity: 0.4 !important;
}

/* Taxpayer UI Design Refresh */

svg {
  overflow: visible;
  vertical-align: middle;
}

.layout-container {
  height: calc(100vh - 85px);
  height: calc(100svh - 85px);
  background-color: #fff;
  overflow: hidden;
  overflow-y: scroll;
}

.screen-share {
  margin-top: 5px;
}

.screen-share a span {
  padding-top: 3px;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 10px;
  color: $brand-primary-blue-1;
}

.steps-navigation-item-sub {
  margin: 50.5px 0;
}

.modal-header .close {
  color: rgba(0, 0, 0, 0.5);
}

.modal-header .close:hover {
  color: rgba(0, 0, 0, 0.5);
}

/* CC Recipient PopupModal Start Here */
.ccRecipientModalPopup {
  overflow-y: hidden !important;

  .modal-dialog {
    display: flex;
    min-width: 679px;
  }

  .modal-body {
    height: 500px;
    overflow-y: auto;
  }
}

.ccRecipientModalPopup input[type="checkbox"] {
  accent-color: $brand-primary-blue-2;
}

.ccRecipientModalPopup .ccRecipientModalDescription {
  font-size: 14px;
  font-weight: 600;
}

.ccRecipientModalPopup .ccRecipientModalDescription .ssr-default-email {
  color: $brand-primary-blue-1;
}

.ccRecipientModalPopup .chkCcRecipientModalSelect {
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15));
  background: var(--gray-white, #fff);
}

.ccRecipientModalPopup .section-header-text {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0.8em;
}

.ccRecipientModalPopup .lblCcRecipientModalSelectAll {
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-left: 7px;
  font-size: 16px;
  position: absolute;
  margin-top: -2px;
}

.ccRecipientModalPopup .ccRecipientModalDownloadableDocument {
  padding-left: 7px;
  margin-bottom: 0.2em;
  font-size: 16px;
  position: absolute;
  margin-top: -2px;
}

.ccRecipientModalPopup .ccRecipientModalBodyContent {
  margin-top: 0.1em;
}

.ccRecipientModalPopup .labelCCRecipientEmail {
  display: flex;
  width: 348px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
}

.ccRecipientModalPopup .labelCCRecipientEmail,
.ccRecipientModalPopup .labelCCRecipientMobile {
  font-weight: 600;
}

.ccRecipientModalPopup .inputCCRecipientEmail {
  display: flex;
  width: 348px;
  align-items: center;
  height: 31px;
  border-radius: 2px;
  padding: 4px 8px;
  flex: 1 0 0;
  border-radius: 2px;
  border: 1px solid $input-border-color;
  background: $white;
  margin-right: 10px;
  margin-bottom: 0.5rem;
  font-family: $font-family;
  outline: none;
}

.ccRecipientModalPopup .inputCCRecipientMobile {
  border-radius: 0px 2px 2px 0px;
  background: $white;
  margin-bottom: 0.5rem;
}

.ccRecipientModalPopup .inputCCRecipientMobile input.form-control {
  height: 31px;
  display: flex;
  align-items: center;
  width: 190px;
  border: 1px solid $input-border-color;
  font-family: $font-family;
}

.ccRecipientModalPopup .inputCCRecipientMobile .flag-dropdown {
  border: 1px solid $input-border-color !important;
  border-radius: 0 0 0 0 !important;
  font-family: $font-family;
}

.ccRecipientModalPopup .inputCCRecipientMobile .flag-dropdown ul {
  width: 250px;
}

.ccRecipientModalPopup .deleteContactContainer {
  cursor: pointer;
  margin-left: 12px;
  margin-top: 2px;
}

.ccRecipientModalPopup .deleteContactContainer img {
  margin-top: -2px;
}

.ccRecipientModalPopup .deleteContactContainer span {
  margin-left: 4px;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.ccRecipientModalPopup .deleteContactContainer svg {
  margin-top: -2px;
}

.ccRecipientModalPopup .btnAddNewCCRecipient {
  display: flex;
  width: 175px;
  height: 31px;
  padding: 4px 14px;
  align-items: center;
  gap: 7px;
  border-radius: 2px;
  border: 1px solid $input-border-color;
  cursor: pointer;
  font-size: 14px;
}

#closeCCRecipientPopover {
  opacity: 1;
  color: black;
  font-weight: 400;
  margin-left: 50%;
  margin-top: 3%;
  z-index: 9999;
}

#closeCCRecipientPopover .popover-header {
  background-color: #fff;
  color: #212529;
  border-bottom: 1px solid #dee2e6;
  line-height: 24px;
  font-size: 20px;
  padding: 9px 1rem;
  border-top-left-radius: 4.8px;
  border-top-right-radius: 4.8px;
}

#closeCCRecipientPopover .arrow {
  display: none;
}

#closeCCRecipientPopover .popover-body {
  font-size: 16px;
}

.ccRecipientPopoverFooter {
  font-family: $font-family;
}

/* CC Recipient PopupModal End Here */

#welcomeMessage,
#completedSummary,
#k1Distribution,
.pay-selector-main {
  .cards-container {
    .cards-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 42px;

      .cards {
        width: 163px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

#welcomeMessage {
  .welcome-container {
    padding-top: 40px;
    margin-bottom: 58px;

    .welcome-message-ready {
      font-size: 24px;

      &.delegatee-completed {
        font-size: 20px;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .cards-container {
      width: 90%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 2000px) {
    .cards-container {
      width: 70%;
      margin: 0 auto;
    }
  }
}

#completedSummary {
  @media screen and (max-width: 768px) {
    .cards-container {
      width: 100%;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 992px) {
    .cards-container {
      width: 90%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1100px) {
    .cards-container {
      width: 80%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1600px) {
    .cards-container {
      width: 50%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 2000px) {
    .cards-container {
      width: 40%;
      margin: 0 auto;
    }
  }
}

#attachmentInstructionPopup {
  overflow-y: hidden !important;
}

#attachmentInstructionPopup .modal-dialog {
  display: flex;
  width: 450px;
}

#attachmentInstructionPopup .modal-body {
  height: auto;
  overflow-y: auto;
}

#paperfileconsentPopup {
  overflow-y: hidden !important;
}

#paperfileconsentPopup.modal-dialog {
  display: flex;
  width: 450px;
}

#paperfileconsentPopup .modal-body {
  height: auto;
  overflow-y: auto;
}

.margin-left-18 {
  margin-left: 18px;
}

.attachment-ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;

  svg {
    margin-bottom: 5px !important;
  }
}

.paperfile-button-container {
  display: flex;
  float: right;
}

.download-all-button {
  width: 156px !important;
  height: auto !important;
  margin-top: -5px;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

    &:hover,
    &:focus,
    &:active {
        svg path {
            fill: $white;
        }
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
            svg path {
                fill: initial;
            }
        }
    }
}

.ccrecipientDownloadAll {
  margin: 0px 18px 16px auto;
}

.filesSection {
  border-top: 0.5px solid #c4c6c8;
  height: calc(100vh - 155px);
  height: calc(100svh - 155px);
  overflow: auto;
}

.paperfileMessageHeader {
  width: calc(100% - 26px);
  font-size: 14px;
  font-family: $font-family;
  color: var(--gray-500, #6b7075);
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
}

.esign-consent-email,
.delegatee-consent-email {
  border-top: $multi-step-border;

  .ugCheckArea {
    padding: 0;
  }
}

.esign-consent-screen {
  &-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 48px;
  }

  .esign-consent {
    margin-top: 14px;

    &-container {
      padding: 0 40px;
    }

    .content-paragraph {
      line-height: 1.4;
    }

    .date-of-birth {
      margin-top: 32px;

      &-label {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 7px;
      }
    }

    .checkbox {
      margin-top: 31px;

      ul {
        margin: 0;

        .eachUGCheck {
          margin: 0;

          label {
            color: #151617;
          }
        }
      }
    }
  }

  .esign-spouse {
    margin-bottom: 20px;

    .spouse-header {
      font-weight: 700;
      line-height: 1.4;
    }

    .content-paragraph {
      line-height: 1.4;
      margin-top: 21px;
      margin-bottom: 31px;
    }

    .spouse-name {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 28px;

      .label {
        color: $primary-font-color;
      }

      .value {
        color: $brand-primary-blue-2;
      }
    }

    .spouse-email {
      .labelEmail {
        margin-bottom: 4px;
      }
    }

    .spouse-mobileNumber {
      .labelMobileNumber {
        margin-bottom: 4px;
      }
    }
  }
}

/* Manual Sign */

.manual-sign-download {
  width: 150px !important;
  height: auto !important;
  margin-top: -5px;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

    &:hover,
    &:focus,
    &:active {
        svg path {
            fill: $white;
        }
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
            svg path {
                fill: initial;
            }
        }
    }
}

.manual-sign-stepper {
  border: 1px #e8e8e8;
  border-style: solid none;

  .step-layout-body-header {
    margin-bottom: 16px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
      color: $primary-font-color;
      margin-top: 76px;
    }
  }
}

.manual-sign-completed {
  .step-layout-body-header {
    margin-bottom: 16px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
      color: $primary-font-color;
      margin-top: 76px;
    }
  }

  .steps-footer {
    border-top: $multi-step-border;
  }
}

.manual-upload-supported-files {
  color: #898d91;
  text-align: center;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.signed-file-delete {
  color: $brand-primary-blue-1;
  /* Body/Regular */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

/* Manual Sign */

.spouse-sign-required-modal {
  .modal-dialog {
    width: 720px;
    max-width: none;
    padding-left: 20px;
    padding-right: 20px;

    .modal-content {
      .modal-body {
        padding: 16px 28px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.4;
      }
    }
  }

  .content-paragraph {
    margin-bottom: 15px;
  }

  .spouseField {
    font-weight: 600;
    margin-bottom: 22px;

    &-value {
      color: $brand-primary-blue-2;
    }
  }

  .spouseEmail {
    margin-right: 17px;
    flex: 1;

    &-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5;
      color: $primary-font-color;
      margin-bottom: 4px;
    }

    .asterisk {
      color: $primary-error;
      font-weight: 700;
    }
  }

  .spouseMobile {
    &-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.5;
      color: $primary-font-color;
      margin-bottom: 4px;
    }
  }

  .flex {
    display: flex;
    margin-bottom: 16px;
  }
}

.assign-delegatee-modal {
  .modal-dialog {
    width: 720px;
    max-width: none;

    .modal-content {
      .modal-body {
        padding: 16px 28px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        max-height: calc(100vh - 180px);
        max-height: calc(100svh - 180px);
        overflow: auto;

        .delegatee {
          &-label {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.5;
            color: $primary-font-color;
            margin-bottom: 4px;
          }
        }

        .asterisk {
          color: $primary-error;
          font-weight: 700;
        }
      }
    }
  }

  .content-paragraph {
    margin-bottom: 5px;
  }

  .mobileSection {
    width: 205px;

    .inputCCMobile .flag-dropdown {
      width: 52px;
    }
  }
}

.delegatee-cancel-modal {
  .modal-dialog {
    width: 450px;
  }

  .modal-body {
    padding: 22px 15px;
    max-height: calc(100vh - 200px);
    max-height: calc(100svh - 200px);
    overflow: auto;

    textarea {
      border-radius: 4px !important;
    }
  }
}

.delegatee-sign-flow {
  #awesome-multi-steps-btn-skip.btn-rounded.success {
    color: white !important;
    border: 1px solid $primary-error !important;
    background-color: $primary-error !important;
    border-radius: 2px !important;
    min-width: 122px;
    float: right !important;
  }

  #awesome-multi-steps-btn-skip.btn-rounded.success:hover {
    background-color: $primary-error !important;
  }
}

.paperfileMessagePrint {
  float: right;

    &:hover,
    &:focus,
    &:active {
        svg path {
            fill: $white;
        }
    }

    &:disabled {
        pointer-events: none;
        cursor: not-allowed;

        &:hover,
        &:focus,
        &:active {
            svg path {
                fill: initial;
            }
        }
    }
}

.rct-collapse *,
.rct-checkbox *,
.rct-node-icon * {
  width: 16px !important;
  margin-bottom: 3px;
}

.rct-title {
  font-weight: 600;
}

.ugRadioInput {
  position: relative;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;

    &:focus-visible + label {
      border: 1px solid black;
    }
  }

  input[type="radio"] + label {
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 7px;
  }

  input[type="radio"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    margin-top: 4px;
  }

  input[type="radio"]:checked + label:before {
    border: 4px solid $brand-primary-blue-2;
  }
}

// Popover Style

.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  border-bottom-color: #f0f0f0 !important;
}

// Bootbox Style

.close:not(:disabled):not(.disabled),
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 1;
}

.bootbox-confirm {
  .modal-dialog {
    width: 450px;
  }
}

.edit-spouse-mail,
.edit-spouse-mobile {
  .modal-body {
    padding: 29px 20px;

    .labelMobileNumber {
      margin-bottom: 4px;
    }
  }
}

@media (min-width: 768px) {
  .type-signature-container {
    // margin-right: 32px;
  }

  .btn-warning.signature-form-group-clear-button {
    min-width: 93px;
  }
}

.footer {
    min-width: unset !important;
}

.clickable {
    cursor: pointer;
}

.not-clickable {
    cursor: default;
}