@import "../variables";

.company_cards_container {
  border: 1px solid #c4c6c8;
  border-radius: 4px;
  height: 200px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  border-top: 5px solid $brand-primary-blue-1;
  max-height: calc(50% - 12px);

  &.full_height_container {
    max-height: 50%;
    min-height: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  &.new_card_container {
    border-color: #669440;
  }

  .company_card_content {
    height: 100%;
  }

  .company_card_header {
    position: relative;
    width: 100%;
    padding: 8px 9px;
    border-bottom: 0.5px solid $gray;

    .title {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.2;
      text-transform: uppercase;
      color: $primary-font-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .company_card_header_left_section_primary {
      width: 80%;

      .company_card_header_location {
        color: #a6a9ac;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
      }

      .company_card_header_name {
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
        color: #212529;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .company_card_header_left_section {
      width: 93%;

      .company_card_header_location {
        color: #a6a9ac;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
      }

      .company_card_header_name {
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
        color: #212529;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .company_card_header_right_section {
    display: flex;
    position: absolute;
    right: 1rem;
    top: 1rem;
    align-items: flex-start;

    .company_card_header_primaryMark {
      margin-right: 0.6rem;
      margin-top: -6px;
    }

    .officeCardMoreOptions {
      display: initial;

      button {
        border: none;
        background-color: transparent;
        padding: 0;
        color: #05386b;
        font-weight: 400;
        min-width: 0px;
        margin-top: -12px;
        display: initial;
      }
    }

    .officeCardMoreOptions > div {
      width: 120px;
      height: 87px;
      border-radius: 4px;
      background-color: #fff;

      .company_card_header_menu_dropdown_edit {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #212529;
        padding: 4px 16px;
        cursor: pointer;
      }

      li:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      .company_card_header_menu_dropdown_remove {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #212529;
        padding: 6px 16px;
        cursor: pointer;
      }

      .disableMenu {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    .company_card_header_menu {
      width: 20px;
      display: flex;
      justify-content: center;
      margin-top: 3px;
      cursor: pointer;
    }

    .company_card_header_menu_dropdown {
      width: 120px;
      height: 87px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      position: absolute;
      right: 0.5rem;
      top: 2.5rem;
      background-color: #fff;
    }
  }
}

.company_card_body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0 9px;

  &.full_height_container {
    height: calc(100% - 81px);
    overflow-y: auto;
  }

  .company_card_body_address1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .company_card_body_address2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .company_card_body_city {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .company_card_body_secondary_website {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.drop_action_button {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 3rem;
  min-height: 3rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  svg {
    margin-bottom: 0.75rem;
  }
}

.drop_action_button_Up {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 1) 25%
  );
  width: 100%;
  height: 6rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  svg {
    margin-bottom: 0.75rem;
  }
}

.new_card_indicator {
  position: absolute;
  margin-top: -15px;
  margin-left: -14px;
}

.company_card_wrapper {
  position: relative;
  min-width: 250px;
  width: auto;
}
