@import "../variables";

.tox-tinymce {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    border: 1px solid $input-border-color !important;
    border-radius: 2px !important;
}

.tox-editor-header {
    box-shadow: none !important;
    z-index: 1 !important;
}

.tox-toolbar-overlord {
    border-top: 1px solid $input-border-color !important;
    border-bottom: 1px solid $input-border-color !important;
}

.tox-toolbar__group {
    border-right: 1px solid $input-border-color !important;
}

.tox-toolbar__group:last-child {
    border-right: 0 !important;
}
