/* Smaller Phone Devices */

@media(min-width:0px) and (max-width:320px) {

    .step-layout-left-panel-body {
        padding: 0px !important;
    }

    .ccdownload-modal {
        min-width: 80%;
        max-width: 80%;
    }
}

 