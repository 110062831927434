@import "../variables.scss";

//---- Global Modal Styling -----
.modal {
    z-index: 1061;
    display: flex !important;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);

    &-dialog {
        display: flex;
        min-width: 450px;
    }

    &-content {
        border-radius: 4.8px;
        box-sizing: border-box;
        border: none;
        background-color: transparent;
    }

    &-header {
        background-color: $white;
        color: $black;
        border-bottom: 1px solid #dee2e6;
        line-height: 24px;
        font-size: 20px;
        padding: 16px;
        border-top-left-radius: 4.8px;
        border-top-right-radius: 4.8px;

        .closeIcon {
            color: $black;
            cursor: pointer;

            svg {
                overflow: visible;
                vertical-align: unset;
            }
        }

        &.darkBackground {
            background-color: $brand-primary-blue-1;
            border: 1px solid $brand-primary-blue-1;

            .modal-title {
                color: $white;
                font-weight: 500;
            }

            .closeIcon {
                svg path {
                    fill: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }

    &-title {
        line-height: 1.2;
        font-size: 20px;
        color: $primary-font-color;
        font-weight: 700;
    }

    &-body {
        height: fit-content;
        background-color: #fff;
        font-size: 16px;
        min-height: 5rem;
        padding: 16px;
    }

    &-footer {
        background: #fff;
        border-bottom-left-radius: 4.8px;
        border-bottom-right-radius: 4.8px;
        padding: 12px;

        .btn-secondary {
            background: #f0f1f1;
            border: 1px solid #898d91 !important;
            border-radius: 2px !important;
            color: $primary-font-color;
            font-size: 16px;
            font-weight: 400;
        }

        .btn-primary {
            border-radius: 2px !important;
            color: #ffffff;
            font-size: 16px;
            margin-left: 8px;
        }

        .btn-danger {
            font-size: 16px;
            border-radius: 2px;
            border: 1px solid var(--theme-error, #CC4A43);
            background: #B8433C;
            margin-left: 8px;
        }

        .btn-disabled,
        .btn-disbaled {
            font-size: 16px;
            border: 1px solid #898d91;
            border-radius: 2px !important;
            cursor: not-allowed;
            pointer-events: all;
        }
    }
}

//Custom Close Icon

.modal .modal-header {
    .btn-close {
        background-size: 17px;
        padding: 8px;
        height: 16px;
        width: 16px;
    }
}

.modal-footer > * {
    margin: 0;
}
