@import "../variables";

.mobileSection {
    position: relative;

    .inputCCMobile {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $input-border-color;
        border-radius: 2px;
        box-sizing: border-box;

        input.form-control {
            border: 0px;
            height: 29.4px;
            width: 100%;
            padding: 6px 8px 6px 58px;
        }

        .flag-dropdown {
            background: #f0f1f1;
            border: none;
            border-right: 1px solid $input-border-color;
            border-radius: 0px;
            width: 23%;

            .selected-flag {
                width: 100%;
            }

            .arrow {
                height: 6px;
                width: 6px;
                border: solid $primary-font-color;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                top: 0;
                margin-top: 1px;
                margin-left: 6px;
            }

            .country-list {
                width: 216px;
                top: 21px;
                font-size: 14px;
                left: 1px;
                font-family: "Mulish", sans-serif;

                .country {
                    padding: 6px 0px 6px 7px;
                }
            }
        }
    }
}

.react-tel-input .selected-flag .arrow.up {
    border-bottom: 0.8px solid $primary-font-color !important;
}
