@import "../variables.scss";

.message-note {
    display: flex;
    height: 63px;
    padding: 16px 16px 16px 16px;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    border-left: 5px solid $brand-primary-blue-2;
    background: var(--sapphire-100, #E6F1F8);
}

.message-note .text{
    background: var(--HitBox, rgba(255, 255, 255, 0.00));
    color: var(--body-text-body-color, #212529);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top:-4px;
}

.message-note .icon {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background: var(--HitBox, rgba(255, 255, 255, 0.00));
    padding-right: 10px;
}
.message-note ul {
     margin-bottom: 0px;
}

.message-note li {
    margin-left: -15px;
}
