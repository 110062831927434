@import '../variables.scss';

.button {
    &-medium {
        min-width: 100px !important;
        min-height: 38px !important;
    }

    &-margin-right {
        margin-right: 8px !important;
    }
}

.button-primary, .button-outline-tertiary, .button-danger {
    &:hover, &:active, &:focus {
        color: $white;
    }
}

.btn {
    border-radius: 2px !important;
}
