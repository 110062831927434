/* Tablet Devices */


@media(min-width:480px) and (max-width:768px) {

    .step-layout-left-panel-body {
        padding: 0px !important;
    }

    .pay-content-right .pay-display-box {
        width: 155px !important;
    }

    .ccdownload-modal {
        min-width: 72%;
        max-width: 72%;
    }

    .pay-margin-top-20 {
        margin-top: 12px !important;
    }

    .image-tile-sizing {
        width: 50%;
        padding: 4%;
        margin: -3%;
    }
}
