.toaster_container {
    .Toastify__toast-container {
        width: 422px;
    }

    .Toastify__toast {
        border-radius: 4px;
        padding: 16px;
        display: flex;
        align-items: center;
        min-height: 56px;
        font-family: "Mulish", sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #212529;
        top: 55px;
        box-shadow: none !important;
    }

    .toaster-icon-message {
        display: flex;
        align-items: center;

        & > svg {
            width: 20px;
        }
    }

    .toaster-message {
        margin: 0 16px 0 8px;
    }

    .toaster-error {
        background-color: #faedec;
        border-left: 5px #b8433c solid;
    }

    .toaster-warning {
        background-color: #fbf5eb;
        border-left: 5px #d69f38 solid;
    }

    .toaster-success {
        background-color: #e9f3ed;
        border-left: 5px #1f8747 solid;
    }

    button {
        color: inherit;
        height: 100%;
        font-weight: normal;
        font-size: 16px;
        margin: auto;
    }
}
