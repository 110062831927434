/* Phone Devices */

@media(min-width:320px) and (max-width:480px) {

    body {
        overflow: auto;
    }
    .ccdownload-modal {
        min-width: 88%;
        max-width: 88%;
    }
    .preparerMessage {
        margin-top: 10px;
        border: 1px solid #e9e9e9;
        height: calc(100vh - 360px) !important;
        overflow-y: auto;
        padding: 20px;
        position: relative;
        font-weight: 500;
    }
    /* payment information popup custom css*/
    .popup-heading {
        font-size: 11px !important;
    }

    .popup-download-icon {
        width: 25px !important;
        height: 40px !important;
    }

    .popup-download-imagediv {
        width: 50px !important;
    }

    .popup-FillingInstruction-div {
        width: 140px !important;
    }

    .popup-download-singleVoucher-icon {
        width: 50px !important;
        height: 40px !important;
    }

    .pay-info-icon-xs {
        cursor: pointer;
        border: 2px solid #8bc656;
        border-radius: 7px;
        margin-top: -16px !important;
        margin-right: 7px;
        max-width: 210px;
    }

    .content-title-xs {
        font-weight: bold !important;
        font-size: 10px !important;
        color: #0973ba !important;
    }
    /*payment information popup custom css*/

    .mydownload-popup-delete {
        margin: 9px !important;
    }

    .mydownload-popup-icon {
        margin-top: -8px;
    }

    .kba-wrapper {
        height: 100% !important;
    }

    .content-wrapper-full, .download-footer {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: inherit !important;
        max-width: none !important;
    }

    .information-popup {
        left: 0 !important;
    }


    .step-layout-left-panel-body {
        padding: 0px !important;
    }

    .pay-content-right .pay-display-box {
        width: 135px !important;
    }

    .content-left {
        height: auto;
    }

    .content-right {
        height: auto;
    }

    .payment-refund-container {
        height: auto;
    }

    .estimated-voucher-container {
        height: auto;
    }
}
