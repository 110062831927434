@import "../variables.scss";

#awesome-multi-steps-btn-next,
#awesome-multi-steps-btn-submit,
#awesome-multi-steps-btn-skip,
#awesome-multi-steps-btn-previous {
    min-width: 122px !important;
}

#awesome-multi-steps-btn-previous svg {
    margin-right: 4px;
}

#awesome-multi-steps-btn-next svg {
    margin-left: 4px;
}

#awesome-multi-steps-btn-submit svg {
    margin-left: 4px;
}

.steps-navigation-container {
    justify-content: center;
}
