@import '../variables.scss';

.react-tel-input .form-control {
    width: 100%
}

.form-check-input:checked {
    background-color:  #0973BA;
}

.form-control {
    width: 100%;
    height: 31px;
    padding: 4px 8px;
    border-radius: 2px !important;
    border: 1px solid $input-border-color;
    color: $primary-font-color;
    background-color: $white;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    font-family: $font-family;

    &:focus {
        border-color: $input-border-color;
    }
}
