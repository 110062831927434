.datepicker-input-container {
    position: relative;
    width: 100%;

    .react-datepicker-wrapper {
        width: 100%;
    }

    input {
        width: 100%;
        height: 32px;
        padding-left: 9px;
        border: 1px solid #898D91;
        border-radius: 2px;
        outline-style: none;
    }

    .datepicker-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}