.consentPopUpBody {
  background: #fff;
  padding: 1rem 1.2rem 1.5rem 1.2rem;

  .consentPopUpBodyHeading {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #000;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    margin-bottom: 1.2rem;

    span {
      font-weight: 700;
    }
  }

  .mobileNumberSection {
    label {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: #212529;
      margin-bottom: 5px;
    }

    .countryCodeMobileNumberWrapper {
      position: relative;
      height: 33px;
      width: 302px;
      border: 1px solid #898d91;
      border-radius: 2px;

      .phoneInput {
        margin-bottom: 3px;

        input {
          height: 31px;
          padding-left: 55px;
        }

        :global .flag-dropdown {
          height: 31px;
          width: 52px;
          border: 0px solid #898d91;
          border-right: 1px solid #898d91;

          .selected-flag {
            width: 50px;
          }

          .arrow {
            border: solid #212529;
            border-width: 0 1px 1px 0;
            height: 6px;
            margin-left: 6px;
            margin-top: 1px;
            top: 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            width: 6px;
          }
        }
      }

      .mobileNumberInput {
        position: absolute;
        font-family: sans-serif, "Poppins";
        height: 31px;
        width: 168px;
        border: 0px solid #898d91;
        color: #212529;
        font-size: 14px;
        font-weight: 400;
        padding: 4px 8px;
        left: 53px;
        top: 0px;
        outline: none;
      }
    }

    .mobileNumberError {
      border: 1px solid #cc4a43 !important;
    }

    .mobileNumberErrorMessage {
      color: #cc4a43;
      font-size: 14px;
    }
  }
}

.consentPopUpFooter {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  button {
    width: 100px;
    height: 38px;
    padding: 6px 12px 6px 12px;
    border-radius: 2px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed !important;
  }

  button:disabled:hover {
    text-decoration: none;
  }
}
