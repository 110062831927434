@import "./variables.scss";

.steps-wrapper {
  height: calc(100vh - 85px);
  height: calc(100svh - 85px);
}

.steps-body > .step {
  overflow: hidden;
}

.steps-body {
  border: none;
}

.steps-body.footer {
  height: calc(100vh - 142px) !important;
  height: calc(100svh - 142px) !important;
}

.rct-node-icon {
  color: #717171;
}

.signing-pdf-icon {
  opacity: 0.5;
}

.container:before,
.container:after {
  display: table;
  content: "";
  zoom: 1 /* ie fix */;
}

.container:after {
  clear: both;
}

.paperfile-consent-container {
  padding: 20px;
}

.paperfile-consent-body {
  height: 150px;
  border: solid 1px #ddd;
  font-size: 17px;
  padding: 10px;
}

.preparerMessageScreen {
  padding-left: 20px;

  .row {
    margin: 0px;
  }
}
.paperfile-consent-header {
  height: 40px;
  padding: 13px;
  background-color: #0973ba;
  color: white;
}

.paperfile-consent-footer {
  height: 40px;
  padding: 5px;
}

.paperfile-consent-footer > button {
  float: right;
  margin-top: 10px;
}

#awesome-pdf-viewer > .main {
  height: calc(100% - 45px) !important;
}

#awesome-pdf-viewer > .main > .right-panel {
  // position: relative;
}

#esign-awesome-pdf-viewer {
  .progress-bar {
    &-active {
      height: calc(100% - 48px) !important;
    }

    &-inactive {
      height: calc(100% - 45px) !important;
    }
  }

  .main {
    height: 100% !important;
  }
}

.review-main-container {
  display: flex;
}

.review-left-panel {
  overflow: auto;
  background-color: white;
  margin: auto;
}

.summary-step-container {
  height: calc(100vh - 194px) !important;
  height: calc(100svh - 194px) !important;
  min-height: 350px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.completed-summary-step-container.row {
  margin: 0;
}

.completed-summary-step-container {
  height: calc(100vh - 85px) !important;
  height: calc(100svh - 85px) !important;
  min-height: 350px;
  padding: 23px 33px;

  .content-left {
    .company_cards_container.full_height_container {
    }

    .company_cards_container {
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
  }
}

.content-left {
  width: 250px;
  height: 100%;
}

.pay-content-top {
  padding-top: 20px;
}

.content-right {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  flex: 1;
}

.payment-step-container {
  display: flex;
  height: 97%;
  margin-top: 10px;
}

.tax-payment-container {
  height: 100%;
  overflow: hidden;
}

.tax-payment-container > span > span {
  width: 100% !important;
}

.payment-refund-container {
  height: 48%;
  margin-right: 0px !important;
  padding-right: 0px !important;
}

.payment-info-modal-title {
  width: 100%;
}

.step-layout {
  height: 100%;
}

.step-layout.with-footer {
  height: calc(100vh - 175px) !important;
  height: calc(100svh - 175px) !important;
}

.step-layout.with-footer .step-layout-body-container {
  border: none;
  border-top: 1px solid #eeeeee;
}

.step-layout.left {
  float: left;
}

.step-layout.center {
  margin: auto;
}

.step-layout.right {
  float: right;
}

.step-layout-header {
  padding: 10px;
  font-size: 18px;
  height: 50px;
  background-color: white;
  font-weight: 700;
  border: 1px #e8e8e8;
  border-style: solid none;
  color: #212529;
  font-family: "Mulish";
}

.step-layout-footer .steps-footer {
  border-top: $multi-step-border;
}

.step-layout-footer,
.step-layout-top-bar {
  margin: auto;
  background-color: white;
}

.step-layout-top-bar {
  padding: 14px !important;
  height: auto;
  /*max-height: 200px;*/
  min-height: 50px;
  overflow: auto;
}

.pay-step {
  display: inline-flex;
}

.step-layout-paper-file-header {
  font-family: Mulish;
  font-size: 18px;
  min-height: 40px;
  padding: 10px 15px 0px 0px;
  font-weight: 700;
  height: auto;
  background-color: white;
  color: var(--body-text-body-color, #212529);
  margin-top: 50px;
}

.step-layout-paper-file-title {
  font-family: Mulish;
  font-size: 16px;
  min-height: 40px;
  padding: 10px 15px 0px 0px;
  font-weight: 600;
  height: auto;
  color: var(--theme-sapphire, #0973ba);
}

.paper-file-acknowledgement {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  width: 76%;
}

.paper-file-print-icon {
  margin-right: 15px;
}

.paper-file-print-info {
  float: right;
  margin-top: 40px;
  margin-right: -210px;
  color: #06f;
}

.paperFileReturnsTbl {
  padding-top: 30px;

  svg {
    margin-right: 8px;
  }

  .table tbody tr {
    border-bottom: 1px solid #c4c6c8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.paperFileReturnsTbl th {
  border: none !important;
  font-size: 14px;
}

.paperFileReturnsTbl td {
  border: none !important;
}

.paper-file-tbl-action-icon {
  color: var(--theme-navy, #05386b);
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  svg {
    margin-right: 8px;
    margin-bottom: 2px;
  }
}

.step-layout-left-panel-header {
  font-size: 20px;
  min-height: 65px;
  padding: 10px 15px 0px 15px;
  font-weight: bolder;
  height: auto;
  background-color: white;
  line-height: inherit;
}

.select-sign-method {
  &-container1 {
    border-top: $multi-step-border;

    .step-layout-body-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
        color: $primary-font-color;
        margin-top: 76px;
      }

      .sub-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        color: $primary-font-color;
        margin-top: 5px;
        margin-bottom: 55px;
      }
    }

    .sign-tab {
      margin: 1px 38px 20px 38px;
    }
  }

  &-container2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.spouse-sign-required {
  border-top: $multi-step-border;

  .step-layout-body-container {
    padding: 0 30px;
  }

  .step-layout-body-header {
    margin-top: 48px;
    margin-bottom: 37px;

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
      color: $primary-font-color;
      margin-top: 76px;
    }
  }

  .links {
    text-decoration-line: underline;
  }

  svg {
    margin-bottom: 3px;
    margin-right: 8px;
  }
}

.signing-completed {
  border-top: $multi-step-border;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-icon {
    margin-bottom: 19px;
  }

  .step-layout-body-header {
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;
      color: $primary-font-color;
    }
  }
}

.summary-screen {
  border-top: $multi-step-border;

  .content-left {
    padding-top: 15px;
  }
}

.step-layout-left-panel-body {
}

.step-layout-left-panel-body,
.step-layout-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.step-layout-body.without-header {
  height: 100% !important;
}

.step-layout-body {
  width: 100%;
}

.step-layout-content {
  height: 100%;
}

.step-layout-header + .step-layout-content {
  height: calc(100% - 50px);
}

.step-layout-body-container {
  margin: 0px auto 0px auto;
}

.step-layout-left-panel {
  background-color: white;
}

.step-layout-left-panel,
.step-layout-body-container {
  border-radius: 2px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.step-layout-body.white {
  background-color: white;
}

.step-layout-body.transparent {
  background-color: transparent;
}

.custom-button {
  background-color: #5ffc5f1c;
  border: 2px solid #83c786;
  height: 65px;
  border-radius: 5px;
  display: flex;
  padding: 8px;
  cursor: pointer;
}

.custom-button.left {
  float: left;
}

.custom-button.center {
  margin: auto;
}

.custom-button.xl {
  width: 250px;
}

.custom-button.lg {
  width: 225px;
}

.custom-button.md {
  width: 175px;
}

.custom-button.sm {
  width: 125px;
}

.custom-button.right {
  float: right;
}

.custom-button:hover {
  background-color: #9fff9f6b;
}

.custom-button-icon-container {
  width: 42px;
}

.custom-button-text-container {
  width: 78%;
  overflow: hidden;
  user-select: none;
  color: #0274bb;
  font-weight: bold;
  font-size: 14px !important;
  margin: auto auto auto 10px;
  font-family: sans-serif, "Roboto", "Poppins";
}

.custom-button.dropdown-toggle.btn.btn-primary {
  background-color: #5ffc5f1c !important;
  border: 2px solid #83c786 !important;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
}

.custom-button.dropdown-toggle.btn.btn-primary:hover {
  background-color: #9fff9f6b !important;
}

.custom-button.dropdown-toggle.btn.btn-primary::after {
  color: #747474;
  font-size: 18px;
}
/* Custom button large */

.custom-large-button {
  background-color: #edeeef;
  border: none;
  width: 202px;
  height: 120px;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-large-button.left {
  float: left;
}

.custom-large-button.center {
  margin: auto;
}

.custom-large-button.right {
  float: right;
}

.custom-large-button:hover {
  background-color: #edeeef;
}

.custom-large-button-icon-container {
  width: 100%;
  text-align: center;
}

.custom-large-button-text-container {
  overflow: hidden;
  user-select: none;
  color: $brand-primary-blue-2;
  font-weight: bolder;
  font-size: 14px !important;
  width: 100%;
  text-align: center;
  padding-top: 10px;
}

.large-button-footer-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  color: $primary-font-color;
  margin-top: 23px;
  text-align: center;
}
/* Custom button large end */
.upload-documents-container {
  height: calc(100% - 25px);
}

.download-documents-area {
  color: $primary-font-color;
  font-feature-settings: "clig" off, "liga" off;
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.upload-documents-title {
  margin: 20px auto;
}

.upload-documents-title img {
  width: 20px;
}

.upload-documents-title strong {
  margin-left: 10px;
}

.text-blue {
  color: #0274bb;
}

.company-info > .company-address {
  min-height: 100px;
  color: $black;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  & > .title {
    color: $primary-font-color;
    font-feature-settings: "clig" off, "liga" off;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
}

.download-documents-area > .downlod-button-container {
  margin: 10px 0px 0px 0px;
}

.company-info > .company-fax {
  min-height: 35px;
  color: $black;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  & > .title {
    color: $primary-font-color;
    font-feature-settings: "clig" off, "liga" off;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
}

#signed-document-upload {
  max-width: 608px;
}

#signed-document-upload.with-border {
  border-radius: 2px;
  border: 1px dashed $primary-blue;
  background: rgba(230, 235, 240, 0.2);
}

.upload-area {
  text-align: center;
  cursor: pointer;
  height: 229px;
}

.upload-area.minimized {
  height: 109px;
  border-radius: 2px;
  border: 1px dashed $primary-blue;
  background: rgba(230, 235, 240, 0.2);
}

.uploaded-files-list {
  height: calc(100% - 120px);
  overflow: auto;
  padding: 10px;
  .table thead th {
    border: none;
    font-size: 14px;
  }

  .table tbody tr {
    border-bottom: 1px solid #c4c6c8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.uploaded-files-list.minimized {
}

.file-list-container {
  display: inline;
  height: 100%;
}

.file-list-container > p {
  margin-bottom: 2rem;
}

.file-list-item {
  border-top: 1px solid #cbd2d9;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
}

.file-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-list-item .icon {
  font-size: 2.4rem;
  margin-right: 1rem;
}

.manual-sign-uploads-table-action-btn {
  color: #8bc34a;
  font-weight: bold;
}

ul.task-items {
  padding: 0px !important;
}

ul.task-items li.item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 2px 0;
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

ul.task-items li.item.type1 .task .icon {
  cursor: pointer;
}

ul.task-items li.item.type1 .task .icon i {
  color: red;
}

ul.task-items li.item .task {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.task-items li.item .task .icon {
  width: 33px;
  height: 31px;
  border-radius: 20px;
  border-color: grey;
  border: 0px solid grey;
  padding: 5px 0px 0px 9px;
}

ul.task-items li.item .task .name {
  margin-left: 10px;
  width: 100%;
  height: 25px;
  max-width: 0px;
}

.uploaded-date-mobile {
  margin-left: 10px;
  font-size: smaller;
}

ul.task-items li.item .dates {
  text-align: center;
  width: 15%;
  min-width: 90px;
}

ul.task-items li.item .dates .bar,
ul.task-items li.item .priority .bar {
  height: 25px;
  border-radius: 15px;
}

ul.task-items li.item .priority {
  text-align: center;
  cursor: pointer;
  width: 15%;
}

.drop-area-highlight {
  background: #fff;
  box-shadow: 0 0 5px 10px rgba(149, 255, 26, 0.22);
}

.btn-upload {
  border-radius: 20px;
  width: 150px;
  height: 38px;
  font-weight: bold;
  padding: 0px 15px 0px 15px;
}

.step-icon {
  height: 100%;
  width: 100%;
}
/* Pay Step - START*/

.pay-content-right {
  height: auto;
  background-color: white;
  height: 100%;
}

.pay-content-left {
  min-width: 260px;
  height: 100%;
}

.voucher-detail-container {
  height: 100%;
}

.voucher-detail-container .top-div {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  height: 90px;
  width: 100%;
}

.voucher-dropdown {
  display: inline-flex;
  margin-top: 14px;
}

.voucher-dropdown .voucher-checkbox {
  padding-right: 5px;
  height: 30px;
  cursor: pointer;
}

.voucher-dropdown .dropdown {
  display: inline;
}

.voucher-dropdown .dropdown-toggle {
  width: 70px;
  text-align: center;
}

.voucher-dropdown .scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
  width: 68px;
}

.voucher-dropdown .show.dropdown {
  display: inline !important;
}

.voucher-list-div {
  border: 2px solid #ccc;
  border-radius: 16px;
  padding: 10px;
  position: relative;
  width: 100%;
  overflow-x: auto;
  height: 80%;
  margin: inherit;
}

.voucher-list-div .section-heading,
.pay-left-panel-list .section-heading {
  color: #0973ba;
  text-align: center;
  margin-bottom: 3%;
  font-weight: 600;
}

.pay-left-panel-list {
  height: calc(100% - 64px);
  background-color: rgba(222, 226, 230, 0.3);
  overflow-x: auto;
  border-top: $multi-step-border;
}

.pay-screen-voucher {
  padding: 0;
  list-style-type: none;
}

section > .section-title {
  color: #0973ba;
  font-size: 15px;
  color: #0973ba;
}

.pay-left-panel {
  font-weight: bold;
  font-size: 14px;
}

.pay-screen-right-pointer {
  position: relative;
  background-color: #0973ba;
  /*background: #0973ba;*/
  height: 45px;
  line-height: 40px;
  margin-bottom: 20px;
  vertical-align: middle;
  color: white !important;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.2);
  width: 100% !important;
}

.pay-content-right .pay-display-box {
  border: 2px solid #ccc;
  min-height: 235px;
  width: 175px;
  border-radius: 3px;
  float: left;
  margin: 14px;
  position: relative;
  text-align: center;
}

.pay-content-right .pay-box {
  border: 2px solid #ccc;
  height: 235px;
  width: 175px;
  border-radius: 5px;
  float: left;
  margin: 25px;
}

.pay-content-right .pay-display-box.done {
  /* background: url("/Content/img/icons/check-mark.png") no-repeat top right; */
  background-size: 55px;
}

.pay-content-right .pay-display-box .pay-date {
  border: 1.5px solid #9e9e9e;
  border-radius: 3px;
  width: 115px;
  position: relative;
  top: -15px;
  left: -10px;
  background-color: #edf7e6;
  padding: 2px;
  text-align: center;
}

.pay-content-right .pay-display-box .pay-icon {
  text-align: center;
  margin: 0 auto;
  height: 40%;
}

.pay-content-right .pay-display-box .pay-fee-name {
  text-align: center;
}

.pay-content-right .pay-display-box .pay-fee-amount {
  text-align: center;
  margin: 0 auto;
  font-weight: 600;
}

.pay-content-right .pay-display-box .pay-button {
  /*margin: 15px 0px 0px 35px;*/
  position: relative;
}
/*    .pay-content-right .pay-display-box .pay-button .btn-secondary {
        min-width: 0;
        border: 2px solid #8bc656;
        background-color: #f3f9ee;
        color: black !important;
        min-width: 100px !important;
        border-radius: 5rem !important;
    }*/
/*
.content-right .pay-display-box .pay-button .btn-secondary:hover {
    background-color: #e8f4dd;
}*/

.pay-content-right .pay-display-box .pay-button .info {
  position: absolute;
  top: 6px;
  right: 9px;
}
/*Payment information popup custom classed*/
.pay-box {
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  background-color: #fafafa;
  margin: 10px 0px 0px -9px;
  /*color: #0973ba;*/
  flex: 1;
  font-weight: bold;
  min-height: 64px;
  /*position: absolute;*/
  bottom: 30px;
  min-height: 315px;
  /*width: 46%;*/
  margin: auto;
  padding: 10px 10px;
}

.content-title {
  font-weight: bold;
  font-size: 13px;
  color: #0973ba;
  border-bottom: 1px dashed #e0e0e0;
  height: 40px;
}

.spacer {
  line-height: 36px;
}

.spacer1 {
  line-height: 20px;
}

.download-singlevoucher {
  text-align: right;
  margin-right: 3px;
  margin-top: -25px;
  padding-bottom: 5px;
  color: #0973ba;
  cursor: pointer;
}

.modal-body .pay-box .action-box-sm .box-image {
  float: left;
  width: 60px;
  padding-left: 10px;
  padding-top: 6px;
  margin-right: 10px;
}

.clearfix {
  clear: both;
}

.tColor {
  color: #0973ba;
  font-weight: bold;
}

.modal-body .pay-box .action-box-sm p.pay-spacer {
  margin-top: 7%;
}

.modal-body .pay-box .action-box-sm.online-icon .box-image {
  padding-top: 10px;
}

.action-box-sm {
  min-height: 60px;
  max-width: 95%;
}

.pay-info-icon {
  cursor: pointer;
  border: 2px solid #8bc656;
  border-radius: 7px;
  margin-top: 68px;
  margin-right: 7px;
  max-width: 210px;
}

.pay-info-text {
  min-height: 140px;
  margin-top: 10px;
}

.pay-filing-instruction-link {
  float: right;
  margin-right: 10px;
  font-family: sans-serif, "Roboto", "Poppins";
  margin-bottom: 10px;
}

.icon-expanded {
  cursor: pointer;
  border: 2px solid #8bc656;
  border-radius: 7px;
  margin-top: 20%;
  margin-right: 7px;
  max-width: 210px;
}

.icon-voucher-expanded {
  cursor: pointer;
  border: 2px solid #8bc656;
  border-radius: 7px;
  margin-top: 28%;
  margin-right: 7px;
  max-width: 210px;
}

.icon-payment-expanded {
  cursor: pointer;
  border: 2px solid #8bc656;
  border-radius: 7px;
  margin-top: 15%;
  margin-left: 52%;
  max-width: 210px;
}

.pay-table {
  line-height: 36px;
}

.pay-icon {
  text-align: center;
  margin: 0 auto;
}

.pay-icon .authority-image {
  padding: 10px;
}

.pay-online-title {
  font-weight: bold;
  font-size: 13px;
  color: #0973ba;
  border-bottom: 1px dashed #e0e0e0;
  height: 58px;
}

.pay-info-online-text {
  min-height: 122px;
  margin-top: 10px;
}

.pay-box-manual {
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  background-color: #fafafa;
  margin: 10px 0px 0px -9px;
  font-weight: bold;
  min-height: 64px;
  bottom: 30px;
  height: 315px;
  margin: auto;
  padding: 10px 10px;
}

.img-responsive {
  height: 50px;
  display: initial;
}

.pay-fee-name {
  text-align: left;
}

.btn-pay-online {
  -webkit-transition: 0.15s ease-in-out !important;
  transition: 0.15s ease-in-out !important;
  padding: 6px;
  font-size: 13px !important;
  margin-left: 6px;
}

.large-text {
  font-size: 22px;
  margin-bottom: 10px;
}

.larger {
  font-size: 14px;
}

td.amount {
  padding-right: 10px;
}

.custom-model {
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  margin-right: 20px;
  margin-top: -27px;
  width: 193px;
  background-color: rgb(243, 249, 238);
  border: 1px solid lightgreen;
}

.btn-radius {
  border-radius: 20px !important;
}
/*Payment information popup custom classed*/
/* Pay Step - END*/
.k1download-button {
  background-color: #5ffc5f1c;
  border: 2px solid #83c786;
  height: 50px;
  border-radius: 5px;
  width: 180px;
  display: flex;
  padding: 5px;
  cursor: pointer;
}

.k1download-button-icon-container {
  width: 25%;
}

.k1download-button-text-container {
  width: 75%;
  overflow: hidden;
  user-select: none;
  color: #0274bb;
  font-weight: bolder;
  margin: auto 0px;
}

.download-helper {
  position: absolute;
  margin-top: 25px;
}

.download-button-helper {
  position: absolute;
  right: 20px;
  border-radius: 5px;
}
.distributeK1Tbl {
  th {
    border-top: none !important;
    font-size: 14px;
  }

  td {
    border-top: none !important;
  }

  ul.pagination {
    float: right;
  }

  .table tbody tr {
    border-bottom: 1px solid #c4c6c8;

    &:last-child {
      border-bottom: none;
    }
  }

  .table {
    input[type="checkbox"] {
      accent-color: $brand-primary-blue-2;
      cursor: pointer;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      border: 1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15));
      background: var(--gray-white, #fff);
    }
  }
}

.distributeManualTbl {
  th {
    border-top: none !important;
    font-size: 14px;
    border-bottom: none !important;
  }

  td {
    border-top: none !important;
  }

  ul.pagination {
    float: right;
  }

  .table tbody tr {
    border-bottom: 1px solid #c4c6c8;

    &:first-child {
      border-top: 1px solid #898d91;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .table {
    input[type="checkbox"] {
      accent-color: $brand-primary-blue-2;
      cursor: pointer;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      border: 1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15));
      background: var(--gray-white, #fff);
    }
  }
}

.manualsign-body {
  height: calc(100vh - 150px) !important;
  height: calc(100svh - 150px) !important;
  border: none;
}

.manualsign-footer {
  padding: 12px;
  height: 58px;
}

.myDownload-delete {
  margin-left: -130%;
  margin-top: 80%;
  color: indianred !important;
  cursor: pointer;
}

.pay-body {
  border: none;
  justify-content: center;
  min-height: 600px;
}

.k1distribution-body {
  border: none;
  justify-content: center;
  min-height: 600px;
  padding: 50px 0px 0px 0px;
}

.kba-wrapper {
  height: calc(100% - 60px);
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.react-checkbox-tree {
  display: block;
}

.download-footer {
  border-radius: 5px;
  position: relative;
  margin: auto;
  background: #fff;
  padding: 15px;
}

.btn.btn-primary.rounded i.fa.fa-save,
.btn.btn-primary.rounded i.fa.fa-times {
  padding-right: 5px;
}

.steps-navigation-item-main {
  border: none !important;
  background-color: none !important;
}

.svg-shadow {
  filter: drop-shadow(3px 3px 3px #dadada);
}

.state-shadow {
  filter: drop-shadow(1px 1px 2px #dadada);
}

table.voucher {
  min-width: 100%;
  border: 1px solid #c0c0c0;
}

.div-clear-btn {
  position: absolute;
  margin-right: 71%;
}

#btn-clear-details {
  float: left;
  border-color: #0274bbb3 !important;
  background-color: #0274bbb3 !important;
}

.btn-clear-details:hover {
  border-color: #0274bb !important;
  background-color: #0274bb !important;
}

.image-col {
  width: 90px;
  padding: 10px;
}

.indent {
  padding-left: 20px;
  /* max-width: 40px;*/
  display: table-cell;
}

.lined {
  border-top: 1px dotted #c0c0c0;
  padding-bottom: 10px;
}

.link-text {
  font-family: sans-serif, "Roboto", "Poppins";
  margin-top: 15px;
}

.my-downloads {
  padding-left: 15px;
  cursor: pointer;
}

.access-ute-clienthub {
  padding-left: 15px;
  cursor: pointer;
}

.clienthublinkdisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sub-title {
  font-weight: bold;
  font-size: 14px;
  height: 100%;
  color: #747474;
}

.attachment-padding {
  padding: 0px 14px !important;
}

.ribbon.ribbon--green {
  background-color: #ffff00;
}

.navigation-start-control-text {
  color: #fa6c09;
}

.ribbon.ribbon--green:after {
  border-top: 1.5rem solid #ffff00;
}

.content-disabled {
  cursor: not-allowed !important;
}

.shepherd-element.shepherd-element-left.top-portion-element.shepherd-enabled,
.shepherd-element.shepherd-element-right.top-portion-element.shepherd-enabled {
  top: 0px !important;
}

li span.rct-text span.rct-node-clickable {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  width: 200px !important;
}

.rct-node-clickable:focus {
  outline: 0;
  background: transparent;
}

.rct-node-clickable:focus-visible {
  outline: 0;
  border: 1px solid black;
}

.file-node-selected {
  outline: 0;
  background: rgba(51, 51, 204, 0.3);
}

.document-control {
  min-height: 10px !important;
  z-index: 1 !important;
}

.attachment-tab-container th {
  border-top: none !important;
  font-size: 14px;
}

.attachment-tab-container td {
  border-top: none !important;
}

.attachment-tab-container th:focus,
.attachment-tab-container td:focus {
  outline: none !important;
}

div#ddlCountryCode {
  width: 150px;
}

.myaccount-text.phonenumber {
  display: inline-flex;
  width: 100%;
}

.labelMobileNumber,
.labelEmail {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  font-family: "Mulish", sans-serif;
  color: $primary-font-color;
}

.pay-container {
  display: inline-flex;
  padding-top: 10px;
}

.custombutton-left .custom-button.right.lg {
  margin-left: 5px;
  width: 200px;
}

.custombutton-left .custom-button-text-container {
  margin: auto;
}

.phoneNumberFullWidth {
  width: 100%;
  font-size: 1rem;
}

.show-inline {
  display: inline-block;
}

.countryDropDownStyling {
  display: inline-block;
  width: 99% !important;
}

.stripe-popup {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4px;
}

.stripe-wrapper {
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-family: "Mulish";
  margin: 0;
}

.countryDropDownStyling .css-g1d714-ValueContainer,
.countryDropDownStyling .css-yk16xz-control {
  height: 38px !important;
}

.checkoutForm {
  .modal {
    &-dialog {
      min-width: 400px !important;
      height: 477px !important;
      overflow: "auto";
    }

    &-header {
      height: 45px;
      padding: 0px 16px 0px 16px;
    }
  }
}

.infoIcon {
  padding-left: 2px;
  padding-bottom: 3px;
}

.creditCardIcon {
  position: absolute;
  top: 31px;
  left: 322px;
}

.stripe-label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 16px !important;
}

.StripeElement {
  display: flex;
  flex-direction: column;
  padding: 6px 12px 6px 8px;
  font-size: 1em;
  font-family: "mulish";
  border: 1px solid #898d91;
  border-radius: 2px;
  margin-top: 4px;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.invoice-amount {
  font-weight: 500;
  color: #0973ba;
  font-size: 32px;
  padding-bottom: 20px;
}

.invoice-amount-label {
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}

.pay-tiles {
  border-radius: 4px;
  position: absolute;
  top: -12px;
  padding: 0 8px;
  text-align: center;
  max-width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;

  &-due {
    width: 154px;
    color: #b8433c;
    border: 1px solid #b8433c;
    background-color: #faedec;
    left: 5px;
  }

  &-paid {
    width: 46px;
    color: #5c853a;
    border: 1px solid #5c853a;
    background-color: #f0f4ec;
    left: 58px;
  }
}

.invoice-box {
  margin: auto;
  padding: 10px 10px;
}

.row-padd {
  padding: 10px !important;
}

.download-invoice {
  margin: 0px 0px 20px;
}

.pay-margin-top-20 {
  margin-top: 20px;
}

.image-tile-sizing {
  width: 50%;
  padding: 4%;
  margin: 1%;
}

.tax-fee-box {
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  background-color: #fafafa;
  margin: 10px 0px 0px -9px;
  font-weight: bold;
  min-height: 64px;
  bottom: 30px;
  height: 315px;
  width: 46%;
  margin: auto;
  padding: 10px 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.pay-now-text {
  min-height: 150px;
}

.font-bold {
  font-weight: bold;
}

@media (min-width: 0px) and (max-width: 320px) {
  .pay-tiles {
    left: 35px !important;
  }
}

@media (min-width: 321px) and (max-width: 400px) {
  .pay-tiles {
    left: 65px !important;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .pay-tiles {
    left: 88px !important;
  }
}

#summary-review,
.delegatee-summary {
  .content-right {
    overflow: auto;
  }
}

.show-inline {
  display: inline-block;
}

.countryDropDownStyling {
  display: inline-block;
  width: 100px !important;
}

.multi-select-widthauto .Select-menu-outer {
  z-index: 999 !important;
  width: auto !important;
  font-size: 11px !important;
}

.showSpouseContactNumber {
  display: inline-block;
  height: 38px !important;
  width: 125px !important;
}

.countryDropDownStyling .css-yk16xz-control {
  height: 38px !important;
}

.multi-select-width100 .Select-menu-outer {
  width: 100% !important;
  font-size: 11px !important;
}

.multi-select-control .Select-menu-outer {
  width: 100%;
  font-size: 11px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.review-background {
  background-color: white;
}

.margin-top-14 {
  margin-top: 14px !important;
}

.updatedCustomButton {
  position: absolute;
  color: #0973ba;
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  top: 0px;
  right: 0px;
  font-size: 20px;
}

.texttooltip {
  visibility: hidden;
  background-color: #ededed;
  color: black;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: -35px;
  right: -10px;
  padding: 5px 15px;
}

.custom-button-container:hover .texttooltip {
  visibility: visible;
}

.shepherd-element-left-adjustment {
  margin-right: 35px !important;
}

/* Pay page*/

/* Small devices */
@media only screen and (min-width: 600px) {
  .voucher-payment-left-panel {
    width: 35% !important;
  }
  .voucher-payment-container {
    width: 65% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .voucher-payment-left-panel {
    width: 35% !important;
  }
  .voucher-payment-container {
    width: 65% !important;
  }
}

@media (max-width: 768px) {
  .pay-left-panel-list {
    margin-top: 56px;
  }

  .payment-download-options {
    margin-top: 10px;
  }

  .payment-card-container {
    height: calc(100% - 100px);
  }

  .step-layout.with-footer {
    height: calc(100vh - 175px) !important;
    height: calc(100svh - 175px) !important;
  }

  .voucher-reminderme-container {
    padding-top: 13px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .voucher-payment-left-panel {
    width: 20% !important;
  }
  .voucher-payment-container {
    width: 80% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .voucher-payment-left-panel {
    width: 20% !important;
  }
  .voucher-payment-container {
    width: 80% !important;
  }
}
.pay-panel-body .step-layout-body,
.pay-panel-body .step-layout-left-panel-body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.pay-display-card {
  border-radius: 4px;
  border: 1px solid var(--components-card-border, rgba(0, 0, 0, 0.17));
  background: var(--gray-white, #fff);
  width: 195px;
  height: 310px;
  float: left;
  margin: 14px;
  position: relative;
}

.pay-display-card-full_height {
  height: 350px;
}

.pay-display-card .card-header {
  text-align: center;
  padding: 8px 16px;
}

.pay-display-card .card-header-default {
  border-bottom: 1px solid var(--components-card-border, rgba(0, 0, 0, 0.17));
  background: var(--theme-light, #f8f9fa);
}

.pay-display-card .card-header-success {
  border-bottom: 1px solid var(--components-card-border, rgba(0, 0, 0, 0.17));
  background: var(--success-100, #e9f3ed);
}

.pay-display-card .card-header-warning {
  border-bottom: 1px solid var(--components-card-border, rgba(0, 0, 0, 0.17));
  background: var(--error-100, #faedec);
}

.payment-card-container .card-body {
  padding: 16px !important;
}

.pay-card-info {
  padding-top: 16px;
}

.pay-card-fee-name {
  color: var(--body-text-body-color, #212529);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding-bottom: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pay-card-fee-amount {
  color: var(--gray-600, #565a5e);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding-bottom: 6px;
}

.pay-card-button {
  border-radius: 2px;
  color: #ffffff;
  font-size: 16px;
}

.dropdownReminderMe {
  border-radius: 2px;
  border: 1px solid var(--gray-400, #898d91);
  background: var(--gray-white, #fff);
  width: 60px;
  padding: 4px 12px 4px 8px;
}

.btn-payment-voucherdownload {
  display: flex;
  width: 185px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus,
  &:active {
    svg path {
      fill: $white;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
        svg path {
            fill: initial;
        }
    }
}
}

.payment-download-options {
  display: flex;
  align-items: center;
  padding-right: 35px;
}

.payment-download-options button:last-child {
  margin-left: 15px;
}

.voucher-payment-container {
  overflow: hidden;
  height: 100%;
}

.steps-header > .voucher-payment-container {
  color: red;
}

.voucher-payment-container .voucher-dropdown {
  margin-top: 0px;
}

.pay-display-card .card-header .icon-voucher-paid {
  position: absolute;
  top: -5%;
  right: -10px;
  padding: 2px;
}

.pay-display-card .card-header .voucher-status-info {
  position: absolute;
  top: -3%;
  right: -16px;
  padding: 4px;
  border-radius: 100px;
  background: var(--theme-shamrock, #669440);
  color: var(--gray-white, #fff);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  width: 50px;
}

.drop_action_button_Down {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 1) 25%
  );
  width: 100%;
  height: 3rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  svg {
    margin-bottom: 0.75rem;
  }
}

.drop_action_button_Up {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.35) 0%,
    rgba(255, 255, 255, 1) 25%
  );
  width: 100%;
  height: 6rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;

  svg {
    margin-bottom: 0.75rem;
  }
}

.company-details {
  grid-column-gap: 2.5%;
  grid-row-gap: 24px;
  -webkit-column-gap: 2.5%;
  column-gap: 2.5%;
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  margin: 1rem 0;
  row-gap: 24px;
}

.vouchertype-custom-text {
  font-size: 15px;
  line-height: 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 35px;
  padding-top: 10px;
}

.donotpay-vouchertype-text {
  font-size: 15px;
  margin-left: -1px;
}

.icon-individual-voucher {
  position: absolute;
  float: right;
  right: 0;
  margin-top: -15px;
  margin-right: 10px;
  cursor: pointer;
}

.tax-payment-info-arrow {
  background: var(--theme-sapphire, #0973ba);
  cursor: pointer;
}

.voucher-payment-left-panel {
  overflow: hidden !important;
}

.tax-payment-header-text {
  height: 50px;
  padding: 22px 0px 0px 20px;
  color: var(--body-text-body-color, #212529);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.tax-payment-header-text span {
  margin-left: 10px;
  cursor: pointer;
}

.voucher-reminderme-container {
  padding-left: 7px !important;
  padding-top: 16px !important;
  padding-bottom: 5px !important;
}

.voucher-reminderme-container .checkbox-inline {
  padding-left: 24px !important;
}

.payment-card-container {
  height: calc(100% - 64px);
  overflow-y: auto;
  padding-top: 5px;
  border-top: $multi-step-border;
  margin-top: 5px;
}

.payment-info-arrow {
  color: #fff !important;
  border: 1px solid #0973ba !important;
  background-color: #0973ba !important;
  border-radius: 2px !important;
  min-width: 122px;
}

.payment-panel-list-header {
  padding: 15px 0px 5px 15px;
  color: var(--body-text-body-color, #212529);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  line-height: 140%;
  text-transform: uppercase;
}

.payment-panel-list-header b {
  font-weight: 800;
}

.payment-panel-list-item {
  position: relative;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  width: 100% !important;
  padding-left: 10px;
  font-size: 14px;
  color: var(--body-text-body-color, #212529);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 250%;
}

.payment-panel-list-item.active {
  background: var(--theme-sapphire, #0973ba);
  color: var(--gray-white, #fff);
}

.payment-panel-list-item .right-arrow {
  margin-top: -2px;
  margin-right: 10px;
  float: right;
}

.pad-left-43 {
  padding-right: 43px;
}

.pay-panel-header-border {
  border-bottom: solid 1px #dee2e6;
  width: 100%;
}

.paycompletedvoucher-container {
  border-bottom: solid 1px #dee2e6;
}

.pay-completed-btn-previous {
  color: #0973ba !important;
  border: 1px solid #0973ba !important;
  background-color: white !important;
  border-radius: 2px !important;
  min-width: 122px !important;
  outline: none !important;
  height: 38px;
}

.payment-paid-indicator {
  position: absolute;
  margin-left: -44px;
  margin-top: 7px;
  color: var(--theme-shamrock, #669440);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.pay-button-container button {
  width: 100%;
}

.voucher-reminderdays-container {
  display: flex;
  width: 100%;
  padding-top: 2px;
}

.voucher-reminderdays-container label {
  padding-left: 10px;
  padding-right: 10px;
}

#drpVoucherReminderDays,
#drpEstimatedVoucherReminderDays {
  width: 60px;
  font-size: 14px;
  border-radius: 2px;
  padding-top: 2px;
}

#yearMonthDateDropdown {
  width: 67px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 2px;
  margin-right: 12px;
}

.yearDropdown {
  &__control {
    width: 75px;
  }

  &__menu {
    width: 75px !important;
  }
}

.tax-payment-header {
  margin-bottom: 14px;
}

.btn-tooltip {
  visibility: hidden;
  background-color: #ededed;
  color: rgb(33, 37, 41) !important;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: -60px;
  padding: 5px 15px;
}

.btn-payment-voucherdownload:hover .btn-tooltip {
  visibility: visible;
}

#btnPayInvoice {
  margin-top: 25px;
}

.invoice-icon {
  margin-top: 15px;
}

.invoice-pay-header-text {
  color: var(--error-600, #b8433c);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.voucherPayementOverlayPopup {
  .modal-dialog {
    display: flex;
    min-width: 400px;
    min-height: 489px;
  }

  .modal-header {
    background-color: $brand-primary-blue-1;
    border: 1px solid $brand-primary-blue-1;

    .closeIcon {
      svg path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-title {
    color: var(--gray-white, $white);
  }

  .modal-footer {
    display: block;
  }

  .modal-footer button.rounded {
    width: 128px;
  }
}

.btn-voucherpaid {
  width: 125px !important;
}

.edit-payment-header {
  padding: 5px;
  margin-top: -6px;
}

.edit-payment-header .mandatory {
  color: var(--theme-error, #cc4a43);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: -4px;
}

.edit-payment-header span:nth-child(2) {
  margin-top: -4px;
  color: var(--body-text-body-color, #212529);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.state-info-text {
  color: var(--body-text-body-color, #212529);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.paymentdetails-container {
  padding: 10px;
  padding-top: 17px;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--gray-200, #c4c6c8);
  width: 365px;
  height: 147px;
  margin-bottom: 10px;
}

.state-name-text {
  color: var(--body-text-body-color, #212529);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.paydue-date-text {
  color: var(--theme-error, #cc4a43);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-radius: 4px;
  border: 1px solid var(--theme-error, #cc4a43);
  background: var(--error-100, #faedec);
  padding: 1px 8px;
  width: 127px;
  margin-top: 5px;
  display: inline-block;
  height: 23px;
}

.currency-wrap {
  position: relative;
}

.currency-code {
  position: absolute;
  left: 8px;
  top: 5px;
  font-size: 14px;
}

.text-currency {
  padding-left: 25px;
  padding-top: 3px;
  height: 31px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid var(--gray-400, #898d91);
  background: var(--gray-white, #fff);
  outline: none;
  font-size: 14px;
}

.datepicker-input-container {
  font-size: 14px;
}

.payment-checknumber {
  height: 31px;
  border-radius: 2px;
  border: 1px solid var(--gray-400, #898d91);
  background: var(--gray-white, #fff);
  width: 100%;
  font-size: 14px;
  padding-left: 10px;
  outline: none;
}

.payment-col-label {
  font-weight: 600;
  font-size: 14px;
  padding-top: 5px;
}

.payment-col-label span {
  font-size: 14px;
  color: var(--theme-error, #cc4a43);
  position: relative;
  bottom: 0px;
  left: 2px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.btn-clear-payment,
.btn-goto-payment {
  border: 1px solid var(--HitBox, rgba(255, 255, 255, 0)) !important;
  background: var(--HitBox, rgba(255, 255, 255, 0)) !important;
  color: var(--theme-navy, #05386b) !important;
}

.fullheight {
  height: 100%;
}

.width150 {
  width: 150px;
}

#chkReminder {
  accent-color: $brand-primary-blue-2;
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid var(--components-checkbox-border, rgba(0, 0, 0, 0.15));
  background: var(--gray-white, #fff);
  margin-top: 7px;
}

/* PaymentInfo Popup */
.payment-information-modal {
  .modal-dialog {
    display: flex;
    width: 500px;
    min-height: 500px;
    max-height: 561px;
    overflow: auto;
  }

  .modal-header {
    background-color: $brand-primary-blue-1;
    border: 1px solid $brand-primary-blue-1;

    .closeIcon {
      svg path {
        fill: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-title {
    color: var(--gray-white, $white);
  }
}

.payment-information-modal .pay-display-card .card-body .pay-card-instruction {
  margin-bottom: 10px;
}

.paymentinfo-authority-container {
  padding: 10px;
  display: flex;
}

.pay-circle {
  position: absolute;
  margin-left: 8px;
  margin-top: 5px;
  color: var(--theme-navy, #05386b);
  text-align: center;
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.payment-instructions-note {
  padding-top: 5px;
  padding-bottom: 5px;
}

.payment-instructions-note svg {
  margin-top: -3px;
}

.payment-instructions-note u {
  margin-left: 5px;
  cursor: pointer;
  color: var(--theme-navy, #05386b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.payment-instructions-step {
  margin-top: 3px;
  margin-left: 15px;
  color: var(--body-text-body-color, #212529);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.paymentinfo-action-container {
  display: flex;
}

.paymentinfo-action-container .pay-display-card {
  height: 165px !important;
  width: 200px !important;
  border: 1px solid var(--gray-200, #c4c6c8);
}

.paymentinfo-action-container .card-header {
  color: var(--body-text-body-color, #212529);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  background: white;
  text-align: left;
}

.paymentinfo-action-container .card-body {
  padding: 1rem;
}

.pay-card-instruction {
  color: var(--body-text-body-color, #212529);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  min-height: 50px;
}

.btn-payinfo-footer {
  background: var(--gray-100, #f0f1f1);
  width: 100%;
  color: var(--theme-navy, #05386b) !important;
}

.btn-payinfo-footer svg {
  margin-left: 5px;
}

.btn-payinfo-footer span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.payment-downloadcard-container .pay-display-card {
  width: 399px !important;
  height: 165px !important;
  margin-left: 30px;
}

.payment-downloadcard-container .pay-display-card .card-header {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  background: var(--gray-white, #fff);
}

.payment-downloadcard-container
  .pay-display-card
  .card-body
  .pay-card-instruction
  div {
  text-align: center;
}

.payment-downloadcard-container .pay-display-card .card-body button {
  width: 200px;
  margin-left: 25%;
}

/* K1 */

.select-k1-method-container {
  .step-layout-content {
    height: calc(100% - 90px) !important;
  }

  .step-layout-body-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;
      color: $primary-font-color;
      margin-top: 50px;
    }

    .sub-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      color: $primary-font-color;
      margin-top: 5px;
      margin-bottom: 55px;
    }
  }
}

.image-caption-subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: $primary-font-color;
  text-align: center;
}

.image-caption-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: $primary-font-color;
  margin-top: 5px;
  text-align: center;
}

.k1-tab {
  margin: 0 38px 20px 38px;
}

.k1-partner {
  display: inline;
  font-weight: 600;
  font-size: 14px;

  .react-tel-input .form-control {
    width: 100%;
  }

  .country-list {
    font-weight: 400;
  }

  .inputCCMobile {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $input-border-color;
    border-radius: 2px;

    input.form-control {
      border: 0px;
      height: 31px;
      width: 100%;
    }
  }
}

.k1-partner-button {
  color: $brand-primary-blue-1;
  padding-right: 15px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
}

.k1-partner-label {
  margin-top: 16px;
  margin-bottom: 4px;
}

.k1-step-layout-text {
  user-select: none;
  font-weight: 400;
  font-size: $font-lg;
  font-family: $font-family;
}

.k1-step-layout-dropdown {
  width: 150px;
  height: auto !important;
  margin-top: -5px;
  border-radius: 2px !important;
  border: 1px solid $brand-primary-blue-2;
  background: $white;
  color: $brand-primary-blue-2;
  display: flex;
  gap: 8px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

  &.btn-outline-primary {
    &:hover {
      border: 1px solid #054570;
      background-color: #054570;
      text-decoration-line: underline;
      color: $white;
    }

    &:focus {
      border: 1px solid #5c636a;
      background-color: #5c636a;
      box-shadow: 0px 0px 0px 4px rgba(108, 117, 125, 0.5);
      text-decoration-line: underline;
      color: $white;
    }

    &:active {
      background: $brand-primary-blue-2 !important;
      color: $white;
    }

    &:disabled {
      pointer-events: none;
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        svg path {
            fill: initial;
        }
      }
    }
  }

  &.dropdown-toggle::after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    svg path {
      fill: $white;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
        svg path {
            fill: initial;
        }
    }
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  color: $white;
  background-color: $brand-primary-blue-2;
  border-color: $brand-primary-blue-2;

  svg path {
    fill: $white;
  }
}

.k1-step-layout-button {
  height: 38px;
  margin-top: -5px;
  display: flex;
  gap: 8px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

  &.email-documents {
    width: auto;
    margin-right: 13px;
  }

  &:hover,
  &:focus,
  &:active {
    svg path {
      fill: $white;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      svg path {
          fill: initial;
      }
    }
  }
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: $brand-primary-blue-2;
  background-color: $white;
  border-color: $brand-primary-blue-2;
}

.list-title {
  font-family: $font-family;
  font-size: $font-lg;
  font-weight: 700;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  white-space: nowrap;
}

.list-sub-title {
  font-family: $font-family;
  font-size: $font-size-md;
  font-weight: 700;
  color: $input-border-color;
}

.k1Search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  height: 37px;
  position: relative;
  width: 350px;
  margin-top: -5px;

  input {
    outline: none;
    border: 1px solid var(--gray-400, #898d91);
    padding: 6px 30px 6px 12px;
    width: 100%;
    min-width: 160px;
    border-radius: 4px 0px 0px 4px;
    background: var(--gray-100, #f0f1f1);
  }

  .searchIcon {
    border: 1px solid $input-border-color;
    background-color: white;
    width: 45px;
    padding: 6px;
    margin-left: -1px;
    color: gray;
    border-radius: 0 4px 4px 0;

    svg {
      width: 16px;
      height: 16px;
      vertical-align: unset;
    }
  }
}

.k1-sub-title {
  font-weight: 400;
  font-size: 16px;
  height: 100%;
  color: $primary-font-color;
  font-family: $font-family;
}

.k1-title {
  font-weight: 700;
  font-size: 18px;
  height: 100%;
  color: var(--body-text-body-color, $primary-font-color);
  font-family: $font-family;
}

.k1-border {
  border-top: $multi-step-border;
}

.k1-status {
  font-weight: 400;
  font-size: 16px;
  color: var(--body-text-body-color, #212529);
  font-family: $font-family;
}

.k1-decline-status {
  font-weight: 400;
  font-size: 14px;
  color: var(--error-600, #b8433c);
  font-family: $font-family;
  border-radius: 4px;
  border: 1px solid var(--error-600, #b8433c);
  background: var(--error-100, #faedec);
  padding: 1px 8px;
}

/* Pay Home Page */

.voucher-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-selector-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .cards-content {
    margin-top: 20px;
  }
}

.pay-selector-main .pay-header p:first-child {
  color: var(--body-text-body-color, #212529);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 5px;
}

.pay-selector-main .pay-header p:last-child {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 55px;
}

.pay-home-container {
  text-align: center;
}

.pay-home-body {
  display: flex;
  padding-top: 120px;
}

.payment-home-icon-container {
  cursor: pointer;
  padding-top: 40px;
  border-radius: 4px;
  width: 163px;
  height: 145px;
  border: 1px solid var(--gray-300, #a6a9ac);
  margin-left: 50px;
  text-align: center;
}

.payment-home-icon-container:first-child {
  margin-left: 20px;
}

.payment-home-icon-container p {
  padding-top: 60px;
}

/* Tax Preparation Modal - Invoice*/

.taxpreparation-information-modal {
  .modal-dialog {
    display: flex;
    overflow: auto;
    max-height: 600px;
    width: 450px !important;
  }

  .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-footer button {
    width: 125px;
  }
}

.taxpreparation-information-modal-ex {
  overflow-y: hidden !important;
  .modal-dialog {
    height: 736px !important;
    min-width: 450px !important;
  }

  .modal-footer button {
    width: 125px;
  }
}

.taxpaid-modal {
  .modal-dialog {
    min-width: 500px !important;
  }
}

.taxpreparation-card-container {
  display: flex;
  padding-top: 15px !important;
}

.taxpreparation-card-container .pay-display-card {
  width: 200px;
  height: 165px;
  border-radius: 4.8px;
  border: 1px solid var(--components-modal-border, rgba(0, 0, 0, 0.2));
  background: var(--gray-white, #fff);
}

.taxpreparation-card-container .pay-display-card .card-header {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  background: var(--gray-white, #fff);
}

.taxpreparation-card-container .pay-display-card .card-body {
  padding: 0.8em;
}

.taxpreparation-card-container
  .pay-display-card
  .card-body
  .pay-card-instruction {
  margin-bottom: 15px;
}

.invoice-body-container {
  display: flex;
}

.invoice-download-card-container .pay-display-card {
  width: 399px !important;
}

.invoice-download-card-container
  .pay-display-card
  .card-body
  .pay-card-instruction
  div {
  text-align: center;
}

.invoice-download-card-container .pay-display-card .card-body button {
  width: 200px;
  margin-left: 25%;
}

.paid-invoice-action {
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
}

.paid-invoice-action button:first-child {
  width: 195px !important;
  float: left;
  margin-left: 0px !important;
  margin-right: 25px !important;
}

.paid-invoice-action button:last-child {
  width: 190px !important;
  float: right;
  margin-left: 0px !important;
}

.btn-invoice-clear {
  position: absolute;
  left: 2px;
  margin-left: 10px !important;
}

.invoice-payment-info-container .row-padd .mandatory-star {
  font-size: 14px;
  color: var(--theme-error, #cc4a43);
  position: relative;
  bottom: 0px;
  left: 2px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

#drpInvoicePaymentMode {
  font-size: 14px;
  border-radius: 2px;
  padding-top: 2px;
  width: 195px;
}

.invoice-payment-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.invoice-payment-info-container .label-text {
  color: var(--body-text-body-color, #212529);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.width195 {
  width: 195px !important;
}

.invoice-pay-remarks {
  resize: none;
}

.btn-invoice-download {
  width: 175px;
  padding: 6px !important;
  color: var(--theme-navy, #05386b) !important;

  &:hover,
  &:focus,
  &:active {
    color: $white !important;

    svg path {
      fill: $white;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      svg path {
          fill: initial;
      }
    }
  }
}

.paperfile-ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;

  svg {
    margin-bottom: 5px !important;
  }
}

.type-signature-container {
  margin: 28px 48px;
  .signature-pad-wrapper {
    height: 170px !important;
    width: 100% !important;

    canvas {
      width: 100% !important;
      height: 170px !important;
    }
  }
}

.k1StepDisabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

#page-thumbnail-view {
  position: relative;
}