.pagination {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 20px;

  .showPageNumber {
    color: #0973ba;
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 0px;
    margin-right: 0.5rem;

    li {
      height: 31px;
      width: 28px;
      list-style: none;
      padding: 6px 6px;
      border: 1px solid #f0f1f1;
      display: flex;
      align-items: center;
      margin-right: 1px;
      cursor: pointer;
      font-weight: bold;

      svg {
        width: 15px;
      }

      span {
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    li.currentPageNumber {
      background-color: #0973ba;
      border: 1px solid #0973ba;
      color: #fff;
      font-weight: normal;
      padding: 1px 9px;
      cursor: default;
    }

    li.paginationActionDisabled {
      color: #c4c6c8;
      background-color: #f0f1f1;
      cursor: not-allowed;
    }
  }

  .configurePagination {
    color: #212529;
    white-space: nowrap;

    .pageSize {
      background-color: #fff;
      border: 1px solid #898d91;
      border-radius: 2px;
      width: 105px;
      height: 31px;
      padding: 0px 8px;
      outline: none;
      color: #565a5e;
      cursor: pointer;

      option {
        color: #565a5e;
      }
    }

    .paginationActionDisabled {
      color: #c4c6c8 !important;
      background-color: #f0f1f1;
      cursor: not-allowed;
      border: 1px solid #c4c6c8 !important;
    }

    .goToPage {
      border: 1px solid #898d91;
      border-radius: 2px;
      outline: none;
      padding: 4px 8px;
      width: 50px;
      height: 31px;
      color: #565a5e;
      text-align: center;
    }
  }
}

@media (max-width: 500px) {
  .pagination {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    .showPageNumber {
      margin-bottom: 9px;
      margin-right: 0px;
    }
  }
}
