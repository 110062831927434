.k1ActivityModalBody {
  padding: 1rem 1rem 2rem;
  overflow-y: scroll;
}
.k1ActivityTable {
  table-layout: fixed;
  color: #212529;
  font-size: 1rem;
  line-height: 24px;
  border-spacing: 0px;
  margin-bottom: 0px;

  thead {
    th {
      padding: 0.5rem 0.3rem 0.3rem;
      font-weight: 400;
      border-top: 0px;
      border-bottom: 2px solid #9ea1a3;
    }

    .status {
      width: 50%;
    }
    .by,
    .date {
      width: 25%;
    }
  }

  tbody {
    .k1DataLoadingArea {
      vertical-align: middle;
      color: #0973ba !important;
      cursor: default;

      .spinner-border {
        border-right-color: lightgray;
        border-bottom-color: lightgray;
        border-left-color: lightgray;
      }

      .k1DataLoadingText {
        color: rgb(136, 136, 136);
        margin-left: 6px;
      }
    }

    tr.eachActivityRow {
      position: relative;
      height: 33px;

      td {
        padding: 4px 4px 3px;
        vertical-align: middle;
        border-bottom: 1px solid #a6a9ac;
      }

      @media (max-width: 500px) {
        td {
          padding: 3px 2px 0px 3px;
          vertical-align: middle;
        }
      }

      td.ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .clickableColumns {
    cursor: pointer;
  }

  .sortIcon {
    color: #6b7075;
    font-size: 11px;
  }
}

.k1ActivityModalFooter {
  gap: 0.5rem;
}
