.css-vi2f54-control:hover {
    border-color: #898D91 !important;
}

.disabled-dropdown {
    pointer-events: none;
    opacity: 0.65;
}

.cursor-nodrop {
    cursor: no-drop;
}

.single-option-container {
	display: flex;
	align-items: center;

	&_label {
		font-size: 14px;
		display: flex;
		align-items: center;
		width: 185px;
        cursor: pointer;

		&--name {
			max-width: 78px;
			color: #212529;
		}

		&--value {
			color: #6B7075;
		}

		&--hyphen {
			margin-left: 3px;
			margin-right: 3px;
			color: #212529;
		}
	}
}